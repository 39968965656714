import React, { useEffect } from "react";
import Header from "./Header";
import PatientList from "./PatientList";
const Home = () => {
  useEffect(() => {
    document.title = "Home | Reception";
  });
  return (
    <div>
      <Header />
      <PatientList />
    </div>
  );
};

export default Home;
