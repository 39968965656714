import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Dna } from "react-loader-spinner";

const PatientList = () => {
  const [patients, getPatients] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const fetchPatientHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/patient/patientsForDoctor",
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }

      const data = await response.json();
      const transformedPatient = data.map((patientList) => {
        return {
          id: patientList._id,
          name: patientList.patientName,
          gender: patientList.gender,
          age: patientList.age,
          ageUnit: patientList.ageUnit,
          city: patientList.city,
          phoneNumber: patientList.phoneNumber,
          appointmentDate: patientList.appointmentDate,
          tokenNo: patientList.tokenNo,
          patientNo: patientList.patientNo,
        };
      });
      setLoading(false);
      getPatients(transformedPatient);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchPatientHandler();
  }, [fetchPatientHandler]);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      fetchPatientHandler();
    }, 30000);
    return () => {
      clearInterval(intervalCall);
    };
  }, [fetchPatientHandler]);

  const navigate = useNavigate();
  const navigateToDetails = (event) => {
    let stringToken = JSON.stringify(event.currentTarget.id);
    const Token = stringToken.replace(/"/g, "");
    navigate("/Doctor/addDetails/" + Token, {
      state: {
        TokenNo: Token,
      },
    });
  };
  const deletePatient = async (event) => {
    const patientID = event.currentTarget.id;
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/patient/delete/" + patientID,
        {
          method: "DELETE",
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (response.ok) {
        fetchPatientHandler();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const searchItems = () => {
      if (searchInput !== "") {
        const filteredData = patients.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase());
        });
        setFilteredResults(filteredData);
      } else {
        setFilteredResults(patients);
      }
    };
    searchItems();
  }, [patients, searchInput]);

  const sentToReception = async (event) => {
    setLoading(true);
    let stringID = JSON.stringify(event.currentTarget.id);
    const ID = stringID.replace(/"/g, "");
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/patient/sentToReception/" + ID,
        {
          method: "PUT",
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
        // toggle();
        fetchPatientHandler();
      }
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert(error);
      }
    }
  };
  let columns = [
    "#",
    "TokenNo",
    "Patient Name",
    "Gender",
    "Age",
    "City",
    "Sent To Reception",
    "Delete",
  ];

  if (localStorage.getItem("role") === "Doctor") {
    columns.splice(6, 0, "Add Details");
  }

  let patientList = (
    <Box align="center" sx={{ boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h4">Patients List</Typography>
      <hr />
      <Typography variant="h6">No Patients Found</Typography>
    </Box>
  );

  if (patients.length > 0) {
    patientList = (
      <>
        <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
          <Box display="flex" justifyContent="space-between" sx={{ m: 1 }}>
            <Typography variant="h4">Patients List</Typography>
            <TextField
              size="small"
              className="search-input"
              icon="search"
              placeholder="Search"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Box>
          <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      size="small"
                      key={column}
                      className="table-font-head"
                      align="center"
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {searchInput.length > 1
                  ? filteredResults.map((patients, index) => {
                      return (
                        <TableRow key={patients.id}>
                          <TableCell
                            scope="row"
                            size="small"
                            className="table-font-row"
                          >
                            {patients.patientNo}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {patients.tokenNo}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {patients.name}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {patients.gender}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {`${patients.age} ${patients.ageUnit}`}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {patients.city}
                          </TableCell>
                          {localStorage.getItem("role") === "Doctor" ? (
                            <TableCell size="small" className="table-font-row">
                              <Button
                                id={patients.tokenNo}
                                onClick={navigateToDetails}
                                variant="outlined"
                                size="medium"
                                className="btn-regular"
                              >
                                Add Details
                              </Button>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          <TableCell size="small" className="table-font-row">
                            <Button
                              id={patients.id}
                              onClick={deletePatient}
                              variant="outlined"
                              size="medium"
                              className="btn-danger"
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : patients.map((patients, index) => {
                      return (
                        <TableRow key={patients.id}>
                          <TableCell
                            scope="row"
                            size="small"
                            className="table-font-row"
                          >
                            {patients.patientNo}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {patients.tokenNo}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {patients.name}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {patients.gender}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {`${patients.age} ${patients.ageUnit}`}
                          </TableCell>
                          <TableCell size="small" className="table-font-row">
                            {patients.city}
                          </TableCell>

                          {localStorage.getItem("role") === "Doctor" ? (
                            <TableCell size="small" className="table-font-row">
                              <Button
                                id={patients.tokenNo}
                                onClick={navigateToDetails}
                                variant="outlined"
                                size="medium"
                                className="btn-regular"
                              >
                                Add Details
                              </Button>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {localStorage.getItem("role") === "Doctor" ? (
                            <TableCell size="small" className="table-font-row">
                              <Button
                                id={patients.tokenNo}
                                onClick={sentToReception}
                                variant="outlined"
                                size="medium"
                                className="btn-progress"
                              >
                                Sent To Reception
                              </Button>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          <TableCell size="small" className="table-font-row">
                            <Button
                              id={patients.id}
                              onClick={deletePatient}
                              variant="outlined"
                              size="medium"
                              className="btn-danger"
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  }

  if (isLoading) {
    patientList = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }
  return <div>{patientList}</div>;
};

export default PatientList;
