import React from "react";
import Home from "./DoctorContent/HomeContent/Home";
import { Route, Routes } from "react-router-dom";
import PatientTreatment from "./DoctorContent/PatientTreatment/PatientTreatment";
import Preview from "./DoctorContent/PrescriptionPrint/Preview";
import TreatmentHome from "./DoctorContent/Treatments/TreatmentHome";
import AddTreatment from "./DoctorContent/Treatments/AddTreatment";
import EditTreatment from "./DoctorContent/Treatments/EditTreatment";
import AllPatientList from "./DoctorContent/AllPatients/PatientList";
import Patient from "./DoctorContent/AllPatients/Patient";
import EditPatientPrescription from "./DoctorContent/AllPatients/EditPatientPrescription";
import NewPatient from "./DoctorContent/NewPatient/NewPatient";
import SideBar from "./UI/SideBar";
import Settings from "./Settings/Settings";
const DoctorRoute = () => {
  return (
    <SideBar>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/allPatients" element={<AllPatientList />}></Route>
        <Route
          exact
          path="/addDetails/:token"
          element={<PatientTreatment />}
        ></Route>
        <Route
          exact
          path="/editPrescription/:token/:visitNo"
          element={<EditPatientPrescription />}
        ></Route>
        <Route
          exact
          path="/PatientDetails/:token"
          element={<Patient />}
        ></Route>
        <Route exact path="/PrintPrescription/*" element={<Preview />}></Route>
        <Route exact path="/Treatments/*" element={<TreatmentHome />}></Route>
        <Route
          exact
          path="/Treatments/AddNewTreatment"
          element={<AddTreatment />}
        ></Route>
        <Route
          exact
          path="/Treatments/EditDiseaseTreatment/:diseaseId"
          element={<EditTreatment />}
        ></Route>
        <Route exact path="/addPatient" element={<NewPatient />}></Route>
        <Route exact path="/settings" element={<Settings />}></Route>
      </Routes>
    </SideBar>
  );
};
export default DoctorRoute;
