import React from "react";
import PatientDetails from "./PatientDetails";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";

const Patient = () => {
  const navigate = useNavigate();
  const navigateToPatientList = () => {
    navigate("/Reception/allPatients");
  };
  return (
    <div>
      <Button
        variant="outlined"
        size="large"
        className="btn-regular"
        onClick={navigateToPatientList}
      >
        Back To Patient List
      </Button>
      <PatientDetails />
    </div>
  );
};
export default Patient;
