import React, { useState } from "react";
import { Dna } from "react-loader-spinner";
import PatientForm from "./PatientForm";
import { Typography, Container } from "@mui/material";
const AddPatient = () => {
  const [patientData, setPatientData] = useState({
    patientName: "",
    age: "",
    ageUnit: "Years",
    city: "",
    phoneNumber: "",
    gender: "",
    weight: "",
    visitNo: 1,
    BP: false,
    bloodSugar: false,
    sentToDoctor: 0,
    online: false,
  });
  const [isLoading, setLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const addPatientHandler = async (data) => {
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/patient/addPatient",
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
        setPatientData({});
        toggle();
      }
      await response.json();
      window.location.reload();
    } catch (error) {
      setLoading(false);
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        console.log("error");
        alert("Something went wrong");
      }
    }
  };

  const receiveChildData = (data) => {
    if (
      data.patientName !== "" ||
      (data.age.length > 0 && data.age.length < 3) ||
      data.phoneNumber !== "" ||
      data.city !== "" ||
      data.gender !== "Gender"
    ) {
      setPatientData(data);
      addPatientHandler(data);
    }
  };

  let patientForm;

  if (isLoading) {
    patientForm = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }

  patientForm = (
    <Container>
      <Typography variant="h4" align="center">
        Add New Patient
      </Typography>
      <hr />
      <PatientForm
        patientData={patientData}
        setPatientData={receiveChildData}
        newPatient={true}
      />
    </Container>
  );

  return patientForm;
};
export default AddPatient;
