import React, { useEffect } from "react";
import PatientDetails from "./PatientDetails";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import PatientTreatment from "./PatientTreatmentDetails";
const Patient = () => {
  const navigate = useNavigate();
  const navigateToBack = () => {
    navigate(-1);
  };
  const { state } = useLocation();
  const tokenNo = state.TokenNo;
  useEffect(() => {
    document.title = "Patient Treatment";
  });
  return (
    <div>
      <Button
        variant="outlined"
        size="large"
        className="btn-regular"
        onClick={navigateToBack}
      >
        Back
      </Button>
      <PatientDetails tokenNo={tokenNo} />
      <PatientTreatment tokenNo={tokenNo} />
    </div>
  );
};
export default Patient;
