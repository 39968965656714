import React from "react";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import TreatmentList from "./TreatmentList";

const TreatmentHome = () => {
  const navigate = useNavigate();
  const navigateAddDisease = () => {
    navigate("/Doctor/Treatments/AddNewTreatment");
  };
  return (
    <div>
      <Button variant="outlined" className="btn-regular" size="large" onClick={navigateAddDisease}>
        Add New Treatment
      </Button>
      <TreatmentList />
    </div>
  );
};

export default TreatmentHome;
