import React, { useState, useEffect } from "react";
import shitalCinicLogo from "./logo/ShitalCliniclogo.png";
// import CliniFyxLogo from "./logo/CliniFyxWebIcon.png"
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Dna } from "react-loader-spinner";

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    userNameMsg: "",
    passwordMsg: "",
  });
  const [credStatus, setCredStatus] = useState("");

  let navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let flag = 0;
  const handleSubmit = async (e) => {
    if (credentials["username"] === "" && credentials["password"] === "") {
      flag = 1;
      setError(true);
      setErrorMsg({
        userNameMsg: "Please Enter Username",
        passwordMsg: "Please Enter Password",
      });
    } else if (credentials["username"] === "") {
      flag = 1;
      setError(true);
      setErrorMsg({
        userNameMsg: "Please Enter Username",
        passwordMsg: "",
      });
    } else if (credentials["password"] === "") {
      flag = 1;
      setError(true);
      setErrorMsg({
        ...errorMsg,
        passwordMsg: "Please Enter Password",
      });
    } else {
      setError(false);
    }

    if (!error && flag === 0) {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/user/login",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              username: credentials.username,
              password: credentials.password,
            }),
          }
        );
        const json = await response.json();
        if (!json.success) {
          setLoading(false);
          setErrorMsg({
            userNameMsg: "Please Enter Valid Username",
            passwordMsg: "Please Enter Valid Password",
          });
          setCredStatus("You have been entered Wrong credentilials!");
        }
        if (json.success) {
          setLoading(false);
          localStorage.setItem("authToken", json.authToken);
          localStorage.setItem("role", json.role);
          localStorage.setItem("name", json.name);
          const userType = json.role;
          if (userType === "Doctor") {
            navigate("/Doctor");
          } else {
            navigate("/Reception");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    let isAuth = localStorage.getItem("role");
    if (isAuth !== "undefined" && isAuth === "Doctor") {
      navigate("/Doctor");
    } else if (isAuth !== "undefined" && isAuth === "Reception") {
      navigate("/Reception");
    } else {
      navigate("/");
    }
  }, [navigate]);

  const onChange = (event) => {
    setErrorMsg({ userNameMsg: "", passwordMsg: "" });
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  let login;

  if (isLoading) {
    login = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  } else {
    login = (
      <div className="center-login">
        <Box sx={{ boxShadow: 5, borderRadius: 5, width: "120vh" }}>
          <Grid container>
            <Grid item xs={6}>
              <Box sx={{ bgcolor: "#cd408f", height: "90vh", borderRadius: 5 }}>
                <img
                  src={shitalCinicLogo}
                  alt="Logo"
                  style={{ height: "35vh" }}
                  className="center-logo"
                />
                <Typography variant="h3" className="italic-h1" align="center">
                  Welcome to Shital Clinic
                </Typography>
                <br/>
                <Typography variant="h6" className="powered" align="center">
                  Powered By CliniFyx
                </Typography>
                <Typography variant="body2" sx={{color:"white"}} align="center">
                  Handcrafted By <br/> Prabir Biswas
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{ bgcolor: "#fafafa", height: "45vh", borderRadius: 5 }}
                align="center"
                className="center-horz-vert"
              >
                <Typography variant="h3">Hello Again!</Typography>
                <Box sx={{ width: "40vh", m: 4 }}>
                  <Box sx={{ height: "10vh" }}>
                    <TextField
                      required
                      id="outlined-required"
                      label="User Name"
                      value={credentials.username}
                      onChange={onChange}
                      name="username"
                      error={errorMsg.userNameMsg.length > 0}
                      helperText={errorMsg.userNameMsg}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ height: "10vh", mt: 2 }}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={errorMsg.passwordMsg.length > 0}
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        required
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={credentials.password}
                        onChange={onChange}
                        name="password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                      <FormHelperText error>
                        {errorMsg.passwordMsg}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Button
                    sx={{ mt: 1 }}
                    variant="outlined"
                    size="large"
                    onClick={handleSubmit}
                    className="btn-regular"
                  >
                    Login
                  </Button>
                  <Box>
                    <Typography variant="h5" color="#e61537">
                      {credStatus}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }

  return login;
};

export default Login;
