import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReceptionHome from "./Components/ReceptionDashboard/ReceptionHome";
import DoctorHome from "./Components/DoctorDashboard/DoctorHome";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route
            path="/Reception/*"
            element={
              <PrivateRoute>
                <ReceptionHome />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/Doctor/*"
            element={
              <PrivateRoute>
                <DoctorHome />
              </PrivateRoute>
            }
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
