import React, { useCallback, useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import TiroDevanagariMarathi from "./font/TiroDevanagariMarathi-Regular.ttf";
import QR from "./QR.png";
Font.register({
  family: "TiroDevanagariMarathi",
  src: TiroDevanagariMarathi,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  tokenNo: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "12pt",
    position: "absolute",
    marginTop: "20",
    marginLeft: "410",
  },
  patientName: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "12pt",
    position: "absolute",
    marginTop: "135",
    marginLeft: "170",
  },
  appointmentDate: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "12pt",
    position: "absolute",
    marginTop: "135",
    marginLeft: "470",
  },
  patientCity: {
    fontFamily: "Helvetica-BoldOblique",
    position: "absolute",
    fontSize: "12pt",
    marginTop: "160",
    marginLeft: "130",
  },
  patientAge: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "12pt",
    position: "absolute",
    marginTop: "160",
    marginLeft: "265",
  },
  patientGender: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "12pt",
    position: "absolute",
    marginTop: "160",
    marginLeft: "365",
  },
  patientWeight: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "12pt",
    position: "absolute",
    marginTop: "160",
    marginLeft: "470",
  },
  examination: {
    marginTop: "190",
    marginLeft: "130",
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "12pt",
  },
  bp: {
    marginTop: "210",
    marginLeft: "170",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "12pt",
  },
  bloodSugar1: {
    marginTop: "210",
    marginLeft: "170",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "12pt",
  },
  bloodSugar2: {
    marginTop: "210",
    marginLeft: "300",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "12pt",
  },
  diagnosis: {
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "12pt",
    marginTop: "230",
    marginLeft: "120",
  },
  medicineTable: {
    marginLeft: "180",
    marginTop: "260",
  },
  table: {
    display: "table",
    width: "350",
  },
  tableHead: {
    fontFamily: "Helvetica-Bold",
    fontSize: "12pt",
  },
  tableCell: {
    // margin: "auto",
    marginLeft: 2,
    marginTop: 5,
    fontSize: "12pt",
  },
  tableCell1: {
    marginLeft: 2,
    marginTop: 5,
    fontSize: "10pt",
  },
  tableRow: {
    flexDirection: "row",
    margin: "auto",
  },
  tableColType: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColName: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColInstruction: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColQty: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColDose: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  instruction: {
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "12pt",
  },
  comment: {
    fontFamily: "Helvetica-Bold",
    fontSize: "12pt",
    position: "absolute",
    marginTop: "500",
    marginLeft: "50",
  },
  nextVisit: {
    fontFamily: "Helvetica-Bold",
    fontSize: "12pt",
    position: "absolute",
    marginTop: "650",
    marginLeft: "50",
  },
  QR: {
    marginTop: "550",
    marginLeft: "70",
    position: "absolute",
    height: 40,
    width: 40,
  },
  appointmentQR: {
    position: "absolute",
    marginTop: "520",
    marginLeft: "40",
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "12pt",
  },
  appointmentQR1: {
    position: "absolute",
    marginTop: "535",
    marginLeft: "60",
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "12pt",
  },
  website: {
    position: "absolute",
    marginTop: "595",
    marginLeft: "40",
    fontSize: "10pt",
  },
});

const PrescriptionData = (props) => {
  const [treatmentData, getTreatmentData] = useState([]);
  const [patientDetail, getPatientDetail] = useState([]);
  const [treatmentDetail, setTreatmentDetail] = useState({});
  let tokenNo = props.tokenNo;
  let visitNo = props.visitNo;

  const fetchTreatmentHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patientTreatment/PatientTreatments/" +
          tokenNo +
          "/" +
          visitNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      const transformedTreatment = data.prescription.map((Treatment) => {
        return {
          medicineType: Treatment.medicineType,
          medicineName: Treatment.medicineName,
          qty: Treatment.qty,
          morning: Treatment.morning,
          afternoon: Treatment.afternoon,
          evening: Treatment.evening,
          night: Treatment.night,
          instruction: Treatment.instruction,
          doseTapering: Treatment.doseTapering,
        };
      });
      setTreatmentDetail(data);
      getTreatmentData(transformedTreatment);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [tokenNo, visitNo]);

  useEffect(() => {
    fetchTreatmentHandler();
  }, [fetchTreatmentHandler]);

  const fetchPatientDetails = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/patientByTokenNoAndVisitNo/" +
          tokenNo +
          "/" +
          visitNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      getPatientDetail(data);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [tokenNo,visitNo]);

  useEffect(() => {
    fetchPatientDetails();
  }, [fetchPatientDetails]);

  const medicineTableRows = treatmentData.map((element, index) => {
    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableColType}>
          <Text style={styles.tableCell1}>{element.medicineType}</Text>
        </View>
        <View style={styles.tableColName}>
          <Text style={styles.tableCell}>{element.medicineName}</Text>
        </View>
        <View style={styles.tableColDose}>
          <Text style={styles.tableCell1}>
            <Text key={element.id}>
              {element.morning === ""
                ? ""
                : element.morning === "0"
                ? ""
                : element.morning + "--"}
            </Text>
            <Text key={element.id}>
              {element.afternoon === ""
                ? ""
                : element.afternoon === "0"
                ? ""
                : element.morning === "" && element.night === ""
                ? "--" + element.afternoon + "--"
                : element.morning === ""
                ? "--" + element.afternoon
                : element.night === ""
                ? element.afternoon + "--"
                : element.afternoon}
            </Text>
            <Text>
              {element.evening === ""
                ? ""
                : element.evening === "0"
                ? ""
                : "--" + element.evening}
            </Text>
            <Text key={element.id}>
              {element.night === ""
                ? ""
                : element.night === "0"
                ? ""
                : element.morning === "" && element.afternoon === ""
                ? "-------" + element.night
                : element.afternoon === ""
                ? "----" + element.night
                : "--" + element.night}
            </Text>
            <Text key={element.id} style={styles.instruction}>
              {element.doseTapering === "None"
                ? ""
                : "\n" + element.doseTapering}
            </Text>
          </Text>
        </View>
        <View style={styles.tableColInstruction}>
          <Text style={[styles.tableCell, styles.instruction]}>
            {element.instruction === "None" ? "" : element.instruction}
          </Text>
        </View>
        <View style={styles.tableColQty}>
          <Text style={styles.tableCell}>{element.qty}</Text>
        </View>
      </View>
    );
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.tokenNo}>Token No : {tokenNo}</Text>
          <Text style={styles.patientName}>{patientDetail.patientName}</Text>
          <Text style={styles.appointmentDate}>
            {treatmentDetail.treatmentDate}
          </Text>
          <Text style={styles.patientCity}>{patientDetail.city}</Text>
          <Text style={styles.patientAge}>
            {patientDetail.age + " " + patientDetail.ageUnit}
          </Text>
          <Text style={styles.patientGender}>{patientDetail.gender}</Text>
          <Text style={styles.patientWeight}>
            {patientDetail.weight !== null ? patientDetail.weight + " kg" : ""}
          </Text>

          {treatmentDetail.bp !== "" || treatmentDetail.bloodSugar !== "" ? (
            <Text style={styles.examination}>ON EXAMINATION</Text>
          ) : (
            ""
          )}
          {treatmentDetail.bp !== "" ? (
            <Text style={styles.bp}>BP: {treatmentDetail.bp} mmHg </Text>
          ) : (
            ""
          )}

          {treatmentDetail.bloodSugar !== "" ? (
            <Text
              style={
                treatmentDetail.bp === ""
                  ? styles.bloodSugar1
                  : styles.bloodSugar2
              }
            >
              Blood Sugar: {treatmentDetail.bloodSugar} mg/dL
            </Text>
          ) : (
            ""
          )}
          <Text style={styles.diagnosis}>
            {treatmentDetail.diagnosis !== undefined
              ? treatmentDetail.diagnosis.length !== 0
                ? "Diagnosis: " + treatmentDetail.diagnosis
                : ""
              : ""}
          </Text>
          <View style={[styles.table, styles.medicineTable]}>
            <View style={[styles.tableRow, styles.tableHead]}>
              <View style={styles.tableColType}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableColName}>
                <Text style={styles.tableCell}>Medication</Text>
              </View>
              <View style={styles.tableColDose}>
                <Text style={styles.tableCell}>Dose</Text>
              </View>
              <View style={styles.tableColInstruction}>
                <Text style={styles.tableCell}>Instruction</Text>
              </View>
              <View style={styles.tableColQty}>
                <Text style={styles.tableCell}>Qty</Text>
              </View>
            </View>
            {medicineTableRows}
          </View>
          <Text style={styles.comment}>{treatmentDetail.comment}</Text>
          <Text style={styles.appointmentQR}>अपॉइंटमेंट बुक करण्यासाठी</Text>
          <Text style={styles.appointmentQR1}>येथे स्कॅन करा</Text>

          <Image src={QR} style={styles.QR} />
          <Text style={styles.website}>shitaldermacosmoclinic.com</Text>
          <Text style={styles.nextVisit}>
            {treatmentDetail.nextVisit === "Not Required"
              ? ""
              : treatmentDetail.nextVisit}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
export default PrescriptionData;
