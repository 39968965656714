import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  CardContent,
  Button,
  CardActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
const TotalPatientsCard = () => {
  const [count, getCount] = useState({});

  const fetchCount = useCallback(async () => {
    await fetch(process.env.REACT_APP_API_URL + "/patient/count", {
      headers: {
        "Auth-Token": localStorage.getItem("authToken"),
      },
    })
      .then((response) => response.json())
      .then((data) => getCount(data))
      .catch((err) => {
        if (err.status === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        }
      });
  }, []);

  useEffect(() => {
    fetchCount();
  }, [fetchCount]);

  let remainingObj = count["remainingPatients"];
  let todaysRegistrationObj = count["todaysRegistration"];
  let totalVisitsObj = count["totalVisits"];

  let remaining;
  let todaysRegistration;
  let totalVisits;

  for (const key in remainingObj) {
    remaining = remainingObj[key].count;
  }

  for (const key in todaysRegistrationObj) {
    todaysRegistration = todaysRegistrationObj[key].count;
  }

  for (const key in totalVisitsObj) {
    totalVisits = totalVisitsObj[key].count;
  }

  const navigate = useNavigate();
  const navigateToAddPatient = () => {
    navigate("/Reception/addPatient");
  };
  return (
    <Box sx={{ m: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card sx={{ minWidth: 250, backgroundColor: "#3a4bb6" }}>
            <Button onClick={navigateToAddPatient} fullWidth>
              <CardContent>
                <Typography sx={{ fontSize: 20 }} color="#fff" gutterBottom>
                  Add New Patient
                </Typography>
              </CardContent>
              <CardActions>
                <EastIcon fontSize="large" sx={{ color: "#fff" }} />
              </CardActions>
            </Button>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ minWidth: 250, backgroundColor: "#e6e880" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.secondary"
                gutterBottom
              >
                Today's Registrations
              </Typography>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignContent="flex-end"
              >
                <Typography variant="h4" component="div">
                  {todaysRegistration !== undefined ? todaysRegistration : 0}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ minWidth: 250, backgroundColor: "#e88085" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.secondary"
                gutterBottom
              >
                Remaining Patients
              </Typography>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignContent="flex-end"
              >
                <Typography variant="h4" component="div">
                  {remaining !== undefined ? remaining : 0}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ minWidth: 250, backgroundColor: "#80dee8" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.secondary"
                gutterBottom
              >
                Total Visited Patients
              </Typography>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignContent="flex-end"
              >
                <Typography variant="h4" component="div">
                  {totalVisits !== undefined ? totalVisits : 0}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TotalPatientsCard;
