import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Box,
  // Dialog,
  // DialogActions,
  // DialogTitle,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Button,
} from "@mui/material";
import { Dna } from "react-loader-spinner";

const PatientList = () => {
  // const [modal, setModal] = useState(false);

  // const toggle = () => {
  //   setModal(!modal);
  // };

  const [patients, getPatients] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const columns = ["#", "TokenNo", "Patient Name", "Sent to Doctor"];
  const fetchPatientHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/patient/patientsForReception",
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }

      const data = await response.json();
      const transformedPatient = data.map((patientList) => {
        return {
          id: patientList._id,
          name: patientList.patientName,
          tokenNo: patientList.tokenNo,
          patientNo: patientList.patientNo,
        };
      });
      getPatients(transformedPatient);
      setLoading(false);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchPatientHandler();
  }, [fetchPatientHandler]);

  const sentToDoctor = async (event) => {
    setLoading(true);
    let stringID = JSON.stringify(event.currentTarget.id);
    const ID = stringID.replace(/"/g, "");
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/patient/sentToDoctor/" + ID,
        {
          method: "PUT",
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
        // toggle();
        fetchPatientHandler();
      }
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  };

  let patientList = (
    <Box align="center" sx={{ boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h4">Patients List</Typography>
      <hr />
      <Typography variant="h6">No Patients Found</Typography>
    </Box>
  );

  if (patients.length > 0) {
    patientList = (
      <Box align="center" sx={{ boxShadow: 3, borderRadius: 2 }}>
        <Typography variant="h4">Patients List</Typography>
        <hr />
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column} size="small" align="center">
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {patients.map((patients, index) => (
                <TableRow
                  key={patients.id}
                  sx={{ "&:last-child td": { border: 0 } }}
                >
                  <TableCell scope="row" size="small" align="center">
                    {patients.patientNo}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {patients.tokenNo}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {patients.name}
                  </TableCell>
                  <TableCell size="small" align="center">
                    <Button
                      id={patients.id}
                      onClick={sentToDoctor}
                      variant="outlined"
                      size="small"
                      className="btn-regular"
                    >
                      Sent to Doctor
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Dialog
          open={modal}
          onClose={toggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Patient have been Sent to Doctor
          </DialogTitle>
          <DialogActions>
            <Button onClick={toggle} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Box>
    );
  }
  if (isLoading) {
    patientList = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }
  return patientList;
};

export default PatientList;
