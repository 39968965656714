import React, { useState, useEffect } from "react";
import EditPatientDetails from "./EditPatientDetails";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import MedicineList from "./MedicineList";
import HistoryModal from "../PatientTreatment/PatientHistoryModal";

const EditPatientPrescription = () => {
  const [openHistory, setOpenHistory] = useState(false);

  const { state } = useLocation();
  const tokenNo = state.TokenNo;
  const visitNo = state.visitNo;
  const navigate = useNavigate();
  const navigateToPatientList = () => {
    navigate("/Doctor/allPatients");
  };
  useEffect(() => {
    document.title = "Edit Patient Prescription";
  });
  const [patientData, setPatientData] = useState({});
  return (
    <>
      <Button
        variant="outlined"
        size="large"
        className="btn-regular"
        onClick={navigateToPatientList}
      >
        Back To All Visited Patient List
      </Button>
      <EditPatientDetails
        tokenNo={tokenNo}
        visitNo={visitNo}
        setPatientData={setPatientData}
        setOpenHistory={setOpenHistory}
      />
      <MedicineList
        tokenNo={tokenNo}
        visitNo={visitNo}
        patientData={patientData}
      />
      <HistoryModal
        open={openHistory}
        onClose={() => setOpenHistory(false)}
        tokenNo={tokenNo}
      />
    </>
  );
};

export default EditPatientPrescription;
