import React from "react";
import Home from "./ReceptionContent/HomeContent/Home";
import { Route, Routes } from "react-router-dom";
import NewPatient from "./ReceptionContent/NewPatient/NewPatient";
import AllPatientList from "./ReceptionContent/AllPatients/PatientList";
import Patient from "./ReceptionContent/AllPatients/Patient";
import DoctorList from "../DoctorDashboard/DoctorContent/HomeContent/Home";
import SideBar from "./UI/SideBar";
const Content = () => {
  return (
    <SideBar>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route exact path="/addPatient" element={<NewPatient />}></Route>
        <Route exact path="/allPatients" element={<AllPatientList />}></Route>
        <Route
          exact
          path="/PatientDetails/:token"
          element={<Patient />}
        ></Route>
        <Route exact path="/DoctorList" element={<DoctorList />}></Route>
      </Routes>
    </SideBar>
  );
};
export default Content;
