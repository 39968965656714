import React, { useState } from "react";
import {
  Grid,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const PatientForm = ({ patientData, setPatientData, newPatient }) => {
  const [data, setData] = useState(patientData);
  let errflag = false;

  const dialogMsg = {
    nameErr: "",
    ageErr: "",
    phoneNumberErr: "",
    cityErr: "",
    genderErr: "",
  };

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "BP") {
      setData({ ...data, [name]: checked });
    } else if (name === "bloodSugar") {
      setData({ ...data, [name]: checked });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const sendDataToParentHandler = () => {
    if (!data.patientName.length > 0) {
      dialogMsg.nameErr = "Please Enter Name!\n";
      errflag = true;
    }
    if (!data.age.length > 0 && !data.age.length <= 3) {
      dialogMsg.ageErr = "Please Enter Age between 1 month to 120 years!\n";
      errflag = true;
    }
    if (data.phoneNumber.toString().length !== 10) {
      dialogMsg.phoneNumberErr = "Please Enter 10 digit Phone Number!\n";
      errflag = true;
    }
    if (!data.city.length > 0) {
      dialogMsg.cityErr = "Please Enter City Name!\n";
      errflag = true;
    }
    if (data.gender === "Gender") {
      dialogMsg.genderErr = "Please Select the Gender!\n";
      errflag = true;
    }
    if (!errflag) {
      console.log(data);
      setPatientData(data);
    } else {
      alert(
        dialogMsg.nameErr +
          dialogMsg.genderErr +
          dialogMsg.phoneNumberErr +
          dialogMsg.ageErr +
          dialogMsg.cityErr
      );
    }
  };

  const resetAll = () => {
    setData(patientData);
  };

  return (
    <Box>
      <Grid mt={2}>
        <FormControl fullWidth>
          <TextField
            required
            label="Patient Name"
            value={data.patientName}
            name="patientName"
            autoFocus
            onChange={handleInputChange}
          />
        </FormControl>
      </Grid>
      <Grid mt={2}>
        <FormControl fullWidth>
          <InputLabel>Gender</InputLabel>
          <Select
            value={data.gender}
            label="Gender"
            name="gender"
            onChange={handleInputChange}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Transgender">Transgender</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid mt={2}>
        <FormControl fullWidth>
          <TextField
            type="number"
            required
            label="Mobile Number"
            value={data.phoneNumber}
            name="phoneNumber"
            onChange={handleInputChange}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            min={0}
          />
        </FormControl>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              required
              type="Number"
              label="Age"
              value={data.age}
              name="age"
              onChange={handleInputChange}
              // onInput={(e) => {
              //   e.target.value = Math.max(0, parseFloat(e.target.value))
              //     .toString()
              //     .slice(0, 3);
              // }}
              // min={0}
              inputProps={{ step: "1", lang: "en-US" }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Age</InputLabel>
            <Select
              value={data.ageUnit}
              label="Age"
              name="ageUnit"
              onChange={handleInputChange}
            >
              <MenuItem value="Months">Months</MenuItem>
              <MenuItem value="Years">Years</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={10}>
          <FormControl fullWidth>
            <TextField
              type="Number"
              label="Weight"
              value={data.weight}
              name="weight"
              onChange={handleInputChange}
              inputProps={{ step: "0.1", lang: "en-US" }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <TextField
              value="kg"
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid mt={2}>
        <FormControl fullWidth>
          <TextField
            required
            label="City"
            value={data.city}
            name="city"
            onChange={handleInputChange}
          />
        </FormControl>
      </Grid>
      <Grid mt={2}>
        <Box align="center">
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleInputChange}
                name="BP"
                value={data.BP}
              />
            }
            label="BP"
          />

          <FormControlLabel
            control={
              <Checkbox
                onChange={handleInputChange}
                name="bloodSugar"
                value={data.bloodSugar}
              />
            }
            label="Blood Sugar"
          />
        </Box>
      </Grid>
      <Grid
        mt={2}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Box>
          <Button
            variant="outlined"
            size="large"
            onClick={sendDataToParentHandler}
            className="btn-regular"
          >
            Submit
          </Button>
          &emsp;
          {newPatient ? (
            <Button
              onClick={resetAll}
              className="btn-regular"
              variant="outlined"
              size="large"
            >
              Reset
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default PatientForm;
