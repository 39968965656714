import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TextField,
  Box,
  FormControl,
} from "@mui/material";
import MedicineTable from "../PatientTreatment/MedicineTable";
import { Dna } from "react-loader-spinner";

const AddTreatment = () => {
  const navigate = useNavigate();
  const navigateToDiseaseList = () => {
    navigate("/Doctor/Treatments/");
  };
  useEffect(() => {
    document.title = "Add New Treatment";
  });
  const [diseaseName, setDiseaseName] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [diseaseErr, setdiseaseErr] = useState("");
  const [error, setError] = useState(false);
  const errorMsg = {
    diseaseErr: "",
    medicine: "",
    qty: "",
  };

  const addTableRows = () => {
    const rowsInput = {
      medicineType: "Tab",
      medicineName: "",
      qty: "",
      doseTapering: "None",
      morning: "1",
      afternoon: "",
      evening: "",
      night: "1",
      instruction: "None",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    let rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  let errorFlag = 0;

  const postDisease = async () => {
    if (diseaseName.length > 0) {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/disease/addDisease",
          {
            method: "Post",
            body: JSON.stringify({
              name: diseaseName,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": localStorage.getItem("authToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.status);
        }
        if (response.status === 200) {
          const data = await response.json();
          setLoading(false);
          return data._id;
        }
      } catch (error) {
        if (error.message === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      }
    } else {
      setError(true);
      setdiseaseErr("Enter Disease Name");
    }
  };

  const postTreatment = async () => {
    let i = 0;
    while (i < rowsData.length) {
      if (rowsData[i].medicineName === "") {
        errorMsg.medicine = "Medicine Name Cannot be empty!\n";
        errorFlag = 1;
      }
      if (rowsData[i].qty === "") {
        errorMsg.qty = "Quantity cannot be empty!\n";
        errorFlag = 1;
      }
      i++;
    }
    if (diseaseName === "") {
      errorMsg.diseaseErr = "Please Enter Disease Name!\n";
      errorFlag = 1;
    }

    if (errorFlag === 0) {
      const diseaseID = await postDisease();
      const AllRowsData = rowsData.map((obj) => {
        return { ...obj, diseaseID: diseaseID };
      });
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            "/prescriptionTemplate/addPrescription",
          {
            method: "Post",
            body: JSON.stringify(AllRowsData),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": localStorage.getItem("authToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.status);
        }
        if (response.status === 200) {
          setLoading(false);
          alert("Disease and Treatment Added Successfully!");
          navigateToDiseaseList();
        }
        await response.json();
      } catch (error) {
        if (error.message === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      }
    } else {
      alert(errorMsg.diseaseErr + errorMsg.medicine + errorMsg.qty);
    }
  };

  let saveBtn = <></>;
  if (rowsData.length > 0)
    saveBtn = (
      <div style={{ marginTop: 20 }}>
        <Button
          variant="outlined"
          size="large"
          className="btn-progress"
          onClick={postTreatment}
        >
          Save Treatment
        </Button>
      </div>
    );

  let addTreatmentForm = (
    <div>
      <Button
        variant="outlined"
        size="large"
        className="btn-regular"
        onClick={navigateToDiseaseList}
      >
        Back To Disease List
      </Button>
      <Box sx={{ mt: 2, boxShadow: 3, borderRadius: 2, p: 2 }}>
        <Box display="flex" justifyContent="space-between" sx={{ m: 1 }}>
          <FormControl sx={{ width: "30%" }}>
            <TextField
              required
              id="outlined-required"
              type="text"
              label="Disease Name"
              value={diseaseName}
              onChange={(e) => setDiseaseName(e.target.value)}
              size="medium"
              error={error}
              helperText={diseaseErr}
            />
          </FormControl>
        </Box>
        <hr />
        <TableContainer sx={{ mt: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  className="table-font-head"
                  size="small"
                ></TableCell>
                <TableCell
                  component="th"
                  className="table-font-head"
                  size="small"
                >
                  Med Type
                </TableCell>
                <TableCell
                  variant="th"
                  size="small"
                  className="table-font-head"
                >
                  Medicine Name
                </TableCell>
                <TableCell
                  component="th"
                  className="table-font-head"
                  size="small"
                >
                  QTY
                </TableCell>
                <TableCell
                  variant="th"
                  size="small"
                  className="table-font-head"
                >
                  Morning
                </TableCell>
                <TableCell
                  variant="th"
                  size="small"
                  className="table-font-head"
                >
                  After
                </TableCell>
                <TableCell
                  variant="th"
                  size="small"
                  className="table-font-head"
                >
                  Evening
                </TableCell>
                <TableCell
                  variant="th"
                  size="small"
                  className="table-font-head"
                >
                  Night
                </TableCell>
                <TableCell
                  component="th"
                  className="table-font-head"
                  size="small"
                >
                  Dose Tapering
                </TableCell>
                <TableCell
                  variant="th"
                  size="small"
                  className="table-font-head"
                >
                  Instruction
                </TableCell>
                <TableCell
                  variant="th"
                  size="small"
                  className="table-font-head"
                >
                  <Button
                    variant="outlined"
                    size="medium"
                    className="btn-regular"
                    onClick={addTableRows}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <MedicineTable
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
                setRowsData={setRowsData}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {saveBtn}
    </div>
  );

  if (isLoading) {
    addTreatmentForm = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }
  return addTreatmentForm;
};

export default AddTreatment;
