import React from "react";
import PatientDetails from "../AllPatients/PatientDetails";
import PatientTreatment from "../AllPatients/PatientTreatmentDetails";
import { Button } from "@mui/material";

const PatientHistoryModal = ({ open, onClose, tokenNo }) => {
  if (!open) return null;
  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <div className="modalRight">
          <Button
            variant="outlined"
            size="medium"
            className="btn-danger closeBtn"
            onClick={onClose}
          >
           Close
          </Button>
          <div className="modal-content">
            <PatientDetails tokenNo={tokenNo} />
            <PatientTreatment tokenNo={tokenNo} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientHistoryModal;
