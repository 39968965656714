import React, { useCallback, useEffect, useReducer } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  Typography,
  Box,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button
} from "@mui/material";

const initialState = {
  data: {
    tokenNo: "",
    patientName: "",
    gender: "",
    age: "",
    ageUnit: "",
    city: "",
    phoneNumber: "",
    weight: "",
    BP: "",
    bloodSugar: "",
  },
  isLoading: true,
  error: null,
};
const formReducer = (formState, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return {
        ...formState,
        data: {
          ...formState.data,
          [action.payload.name]: action.payload.value,
        },
      };
    case "FETCH_SUCCESS":
      return {
        ...formState,
        data: action.payload,
        isLoading: false,
      };
    case "FETCH_ERROR":
      return {
        ...formState,
        error: action.payload,
        isLoading: false,
      };
    default:
      return formState;
  }
};
const PatientDetails = ({ setPatientData, setOpenHistory, ...props }) => {
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const fetchPatientDetails = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/patientByTokenNoAndVisitNo/" +
          props.tokenNo +
          "/" +
          props.visitNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      dispatch({ type: "FETCH_SUCCESS", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error });
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [props.tokenNo, props.visitNo]);

  useEffect(() => {
    fetchPatientDetails();
  }, [fetchPatientDetails]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch({ type: "UPDATE_FIELD", payload: { name, value } });
  };

  const {
    tokenNo,
    patientName,
    gender,
    age,
    ageUnit,
    city,
    phoneNumber,
    weight,
    BP,
    bloodSugar,
  } = formState.data;

  useEffect(() => {
    setPatientData(formState.data);
  }, [formState.data, setPatientData]);

  return (
    <div>
      <Box sx={{ mt: 2, boxShadow: 2, borderRadius: 3, p: 1 }}>
        <Box display="flex" justifyContent="space-between" sx={{ m: 1 }}>
          <Typography variant="h4">Patient Treatment Details</Typography>
          <Typography variant="h6">Token No: {tokenNo}</Typography>
        </Box>
        <hr />
        <TableContainer sx={{ m: 2 }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    required
                    label="Patient Name"
                    name="patientName"
                    value={patientName || ""}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: 250 }}
                  />
                </TableCell>
                <TableCell sx={{ pr: 0 }}>
                  <TextField
                    required
                    label="Age"
                    name="age"
                    value={age || ""}
                    sx={{ width: 70 }}
                    onChange={(e) => handleChange(e)}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 3);
                    }}
                    min={0}
                  />
                </TableCell>
                <TableCell sx={{ pl: 0 }}>
                  <FormControl fullWidth>
                    <InputLabel>Age</InputLabel>
                    <Select
                      name="ageUnit"
                      value={ageUnit}
                      label="Age"
                      onChange={(e) => handleChange(e)}
                      sx={{ width: 90 }}
                    >
                      <MenuItem value="Months">Months</MenuItem>
                      <MenuItem value="Years">Years</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    label="City"
                    name="city"
                    value={city || ""}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: 150 }}
                  />
                </TableCell>
                <TableCell>
                  <FormControl>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      value={gender || ""}
                      name="gender"
                      label="Gender"
                      onChange={(e) => handleChange(e)}
                      sx={{ width: 120 }}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Transgender">Transgender</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    label="Phone Number"
                    name="phoneNumber"
                    value={phoneNumber || ""}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: 130 }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    min={0}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    label="Weight"
                    name="weight"
                    value={weight || ""}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: 100 }}
                  />
                </TableCell>
                <TableCell>
                  <FormControl>
                    <InputLabel>BP</InputLabel>
                    <Select
                      size="medium"
                      value={BP || ""}
                      name="BP"
                      label="BP"
                      onChange={(e) => handleChange(e)}
                      sx={{ width: 100 }}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl>
                    <InputLabel>Diabetes</InputLabel>
                    <Select
                      size="medium"
                      value={bloodSugar || ""}
                      name="Diabetes"
                      label="Diabetes"
                      onChange={(e) => handleChange(e)}
                      sx={{ width: 100 }}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="center">
                  {props.visitNo > 1 ? (
                    <Button
                      onClick={() => setOpenHistory(true)}
                      variant="outlined"
                      size="small"
                      className="btn-regular"
                    >
                      See History
                    </Button>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};
export default PatientDetails;
