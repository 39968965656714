import React, { useEffect, useState } from "react";
import {
  Table,
  Box,
  Typography,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@mui/material";
import { Dna } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const PatientTreatment = (props) => {
  const [treatment, setTreatment] = useState([]);
  const [isLoading, setLoading] = useState(false);

  let tokenNo = props.tokenNo;

  useEffect(() => {
    const fetchTreatment = async () => {
      setLoading(true);
      await fetch(
        process.env.REACT_APP_API_URL +
          "/patientTreatment/PatientTreatments/" +
          tokenNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setTreatment(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.status === "401") {
            alert("You have been logged out !");
            localStorage.clear();
            window.location.href = "/";
          } 
        });
    };
    fetchTreatment();
  }, [tokenNo]);

  const navigate = useNavigate();
  const navigateToPrint = (e) => {
    const visitNo = e.target.id;
    navigate("/Doctor/PrintPrescription/?tokenNo=" + tokenNo, {
      state: {
        tokenNo: tokenNo,
        visitNo: visitNo,
      },
    });
  };

  let treatmentData = (
    <div>
      <h6>No Details Found</h6>
    </div>
  );

  if (isLoading) {
    treatmentData = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }

  const navigateToEdit = (event) => {
    let strVisitNo = JSON.stringify(event.currentTarget.id);
    const visitNo = strVisitNo.replace(/"/g, "");
    navigate("/Doctor/editPrescription/" + tokenNo + "/" + visitNo, {
      state: {
        TokenNo: tokenNo,
        visitNo: visitNo,
      },
    });
  };
  if (treatment.length > 0) {
    treatmentData = (
      <Box sx={{ mt: 2, boxShadow: 2, borderRadius: 3, p: 1 }}>
        {treatment.map((treatment, index) => {
          return (
            <>
              <Box sx={{ boxShadow: 2, borderRadius: 3, m: 1 }} key={index}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow key={index}>
                        <TableCell align="center">
                          <Typography variant="h6">Visit No</Typography>
                          <Typography variant="subtitle1">
                            {treatment.visitNo}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6">Disease</Typography>
                          <Typography variant="body1">
                            {treatment.diseaseName}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6">Treatment Date</Typography>
                          <Typography variant="body1">
                            {treatment.treatmentDate}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6"> Next Visit</Typography>
                          <Typography variant="body1">
                            {treatment.nextVisit}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ ml: 3, p: 1 }}>
                  <Typography variant="h6" display="inline">
                    Diagnosis:
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography variant="body1" display="inline">
                    {treatment.diagnosis !== undefined
                      ? treatment.diagnosis
                      : ""}
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography variant="body1" display="inline">
                    {treatment.bp !== "" ? "BP: " + treatment.bp : ""}
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography variant="body1" display="inline">
                    {treatment.bloodSugar !== ""
                      ? "BloodSugar: " + treatment.bloodSugar
                      : ""}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell size="small" className="table-font-head">
                            #
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Medicine Type
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Medicine Name
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Quantity
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Morning
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Afternoon
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Evening
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Night
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Dose Tapering
                          </TableCell>
                          <TableCell size="small" className="table-font-head">
                            Instruction
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {treatment.prescription.map((prescription, index) => {
                          return (
                            <TableRow key={index} scope="row">
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.medicineType}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.medicineName}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.qty}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.morning === "" ||
                                prescription.morning === "0"
                                  ? "-"
                                  : prescription.morning}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.afternoon === "" ||
                                prescription.afternoon === "0"
                                  ? "-"
                                  : prescription.afternoon}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.evening === "" ||
                                prescription.evening === "0"
                                  ? "-"
                                  : prescription.evening}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.night === "" ||
                                prescription.night === "0"
                                  ? "-"
                                  : prescription.night}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.doseTapering === "None"
                                  ? "-"
                                  : prescription.doseTapering}
                              </TableCell>
                              <TableCell
                                size="small"
                                className="table-font-row"
                              >
                                {prescription.instruction}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              <Box
                sx={{ m: 1, p: 2, boxShadow: 2, borderRadius: 3 }}
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  {treatment.comment.length > 0 ? (
                    <>
                      <Typography variant="h6" display="inline">
                        Other Details:{"  "}
                      </Typography>
                      <Typography variant="body1" display="inline">
                        {treatment.comment}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="h6" display="inline"></Typography>
                  )}
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    className="btn-progress"
                    onClick={navigateToEdit}
                    id={treatment.visitNo}
                  >
                    Edit Prescription
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="outlined"
                    size="large"
                    className="btn-regular"
                    onClick={navigateToPrint}
                    id={treatment.visitNo}
                  >
                    Print Prescription
                  </Button>
                </Box>
              </Box>
            </>
          );
        })}
      </Box>
    );
  }
  return treatmentData;
};

export default PatientTreatment;
