import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PatientDetails from "./PatientDetails";
import MedicineList from "./MedicineList";
import { Box, FormControl, TextField, Grid, Button } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import HistoryModal from "./PatientHistoryModal";
const filter = createFilterOptions();

const PatientTreatment = () => {
  const [diseases, getDisease] = useState([]);
  const [diseaseName, setDiseaseName] = useState("");
  const [bp, setBP] = useState("");
  const [bloodSugar, setBloodSugar] = useState("");
  const [showMediTable, setShowMediTable] = useState(false);
  const [visitNo, getVisitNo] = useState();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [patientData, setPatientData] = useState("");
  const [openHistory, setOpenHistory] = useState(false);

  const fetchDiseaseHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/disease/diseases",
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      const transformedDisease = data.map((disease) => {
        return {
          id: disease._id,
          name: disease.name,
        };
      });
      getDisease(transformedDisease);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchDiseaseHandler();
  }, [fetchDiseaseHandler]);

  const { state } = useLocation();
  const tokenNo = state.TokenNo;

  const handleFetchDiseaseTreatment = () => {
    if (diseaseName === null || diseaseName.length === 0) {
      setError(true);
      setErrorMsg("Please Enter Disease Name");
    } else {
      setError(false);
      setErrorMsg("");
      setShowMediTable(true);
    }
  };
  // const cursorJump = (event) => {
  //   if (diseaseName !== null) {
  //     if (diseaseName.name.substring(0, 3) === "Add") {
  //       event.target.selectionStart = diseaseName.name.length - 1;
  //       event.target.selectionEnd = diseaseName.name.length - 1;
  //     } else {
  //       event.target.selectionStart = diseaseName.length;
  //       event.target.selectionEnd = diseaseName.length;
  //     }
  //   }
  // };
  return (
    <div>
      <PatientDetails
        tokenNo={tokenNo}
        visitno={getVisitNo}
        setPatientData={setPatientData}
        setOpenHistory={setOpenHistory}
      />
      <Box sx={{ width: 900, mt: 2 }}>
        <Grid container>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                freeSolo
                options={diseases}
                getOptionLabel={(option) => option.name || ""}
                style={{ width: 290 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // onFocus={cursorJump}
                    label="Select Disease"
                    variant="outlined"
                    error={error}
                    helperText={errorMsg}
                  />
                )}
                value={diseaseName}
                onChange={(event, newValue) => {
                  setDiseaseName(newValue);
                  setShowMediTable(false);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Box m={1}>
              <Button
                variant="outlined"
                size="large"
                className="btn-regular"
                onClick={handleFetchDiseaseTreatment}
              >
                Get Data
              </Button>
            </Box>
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-required"
              label="BP"
              value={bp}
              onChange={(e) => setBP(e.target.value)}
              display="inline"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-required"
              label="Blood Sugar"
              value={bloodSugar}
              onChange={(e) => setBloodSugar(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      {showMediTable && (
        <MedicineList
          diseaseData={diseaseName}
          tokenNo={tokenNo}
          visitNo={visitNo}
          bp={bp}
          bloodSugar={bloodSugar}
          patientData={patientData}
        />
      )}
      <HistoryModal
        open={openHistory}
        onClose={() => setOpenHistory(false)}
        tokenNo={tokenNo}
      />
    </div>
  );
};
export default PatientTreatment;
