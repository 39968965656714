import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  FormControl,
  Box,
  TextField,
  Container,
} from "@mui/material";

const SecondVisit = ({ tokenNumber, details }) => {
  const [tokenNo, setTokenNo] = useState("");

  const handleChange = (e) => {
    setTokenNo(e.target.value);
    details(false);
  };

  const handleChangeToken = () => {
    tokenNumber(tokenNo);
    details((current) => !current);
  };
  return (
    <Container>
      <Box>
        <Typography variant="h4" align="center">
          Search Patient
        </Typography>
        <hr />
      </Box>
      <Grid mt={3}>
        <FormControl fullWidth>
          <TextField
            required
            id="outlined-required"
            label="Patient Token No."
            value={tokenNo}
            autoFocus
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid
        mt={2}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Button
          onClick={handleChangeToken}
          variant="outlined"
          size="large"
          className="btn-regular"
        >
          Find Patient
        </Button>
      </Grid>
    </Container>
  );
};

export default SecondVisit;
