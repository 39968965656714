import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHouseUser,
  faHospitalUser,
  faBed,
  faSignOut,
  faBedPulse,
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/Reception",
      name: "Home",
      icon: <FontAwesomeIcon icon={faHouseUser} />,
    },
    {
      path: "/Reception/DoctorList",
      name: "Doctor Patient List",
      icon: <FontAwesomeIcon icon={faBedPulse} />,
    },
    {
      path: "/Reception/addPatient",
      name: "Add New Patient",
      icon: <FontAwesomeIcon icon={faHospitalUser} />,
    },
    {
      path: "/Reception/allPatients",
      name: "Patients",
      icon: <FontAwesomeIcon icon={faBed} />,
    },
  ];
  const logout = () => {
    localStorage.clear();
  };
  return (
    <div className="App wrapper">
      <div
        style={{
          width: isOpen ? "280px" : "60px",
          position: "sticky ",
          top: 0,
        }}
        className="sidebar"
      >
        <div className="top_section">
          <Typography
            style={{ display: isOpen ? "block" : "none" }}
            variant="h5"
          >
            Shital Clinic
          </Typography>
          <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
            <FontAwesomeIcon icon={faBars} onClick={toggle} className="icon" />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink to={item.path} key={index} className="link">
            <div className="icon">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
        <div className="bottom-sidebar">
          <div className="center-flex">
            <Typography
              sx={{ display: isOpen ? "block" : "none" }}
              variant="body1"
            >
              {localStorage.getItem("name")}
            </Typography>
          </div>
          <NavLink to={"/"}>
            <div className="log-out center-flex" onClick={logout}>
              <FontAwesomeIcon icon={faSignOut} className="icon" />
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                &nbsp;Log out
              </div>
            </div>
          </NavLink>
          <br />
          <div className="center-flex">
            <Typography
              sx={{ display: isOpen ? "block" : "none" }}
              variant="body1"
            >
              Powered By CliniFyx
            </Typography>
          </div>
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
