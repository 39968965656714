import React, { useEffect, useState } from "react";
import {
  Table,
  Box,
  Typography,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { Dna } from "react-loader-spinner";

const PatientTreatment = (props) => {
  const [treatment, setTreatment] = useState([]);
  const [isLoading, setLoading] = useState(false);

  let tokenNo = props.tokenNo;

  useEffect(() => {
    const fetchTreatment = async () => {
      setLoading(true);
      await fetch(
        process.env.REACT_APP_API_URL +
          "/patientTreatment/PatientTreatments/" +
          tokenNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setTreatment(data);
          setLoading(false);
        })
        .catch((err) => {
          if (err.status === "401") {
            alert("You have been logged out !");
            localStorage.clear();
            window.location.href = "/";
          }
        });
    };
    fetchTreatment();
  }, [tokenNo]);

  let treatmentData = <Typography variant="h6">No Details Found</Typography>;

  if (isLoading) {
    treatmentData = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }

  if (treatment.length > 0) {
    treatmentData = (
      <Box sx={{ mt: 2, boxShadow: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h6">Visit No</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">Disease</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">Treatment Date</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6"> Next Visit</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {treatment.map((treatment, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Typography variant="body1">
                        {treatment.visitNo}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1">
                        {treatment.diseaseName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1">
                        {treatment.treatmentDate}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1">
                        {treatment.nextVisit}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }

  return treatmentData;
};

export default PatientTreatment;
