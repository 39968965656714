import React, { useEffect, useState } from "react";
import { Dna } from "react-loader-spinner";
import { Typography, Button, Box } from "@mui/material";
import PatientForm from "./PatientForm";

const PatientDetails = ({ visitState, tokenState, ...props }) => {
  const [patientDetail, getPatientDetail] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [resetData, setResetData] = useState(false);

  useEffect(() => {
    const fetchPatientDetails = async () => {
      setLoading(true);
      await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/patientByTokenNo/highestVisit/" +
          props.tokenNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          getPatientDetail(data);
          setLoading(false);
        })
        .catch((err) => {
          if (err.status === "401") {
            alert("You have been logged out !");
            localStorage.clear();
            window.location.href = "/";
          }
        });
    };
    fetchPatientDetails();
  }, [props.tokenNo]);

  const addPatientHandler = async (data) => {
    setLoading(true);
    data.visitNo += 1;
    data.sentToDoctor = 0;
    const newDetail = { online: false };
    getPatientDetail((prevState) => ({
      newDetail,
      ...prevState,
    }));
    await fetch(process.env.REACT_APP_API_URL + "/patient/addRepeatPatient", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        res.json();
        setLoading(false);
        visitState("");
        tokenState("");
        setResetData(true);
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const receiveChildData = (data) => {
    if (
      data.patientName !== "" ||
      (data.age.length > 0 && data.age.length < 3) ||
      data.phoneNumber !== "" ||
      data.city !== "" ||
      data.gender !== "Gender"
    ) {
      getPatientDetail(data);
      addPatientHandler(data);
    }
  };

  let dataHTML;

  const handleError = () => {
    visitState("Yes");
    setResetData(true);
  };
  if (patientDetail === null && resetData === false) {
    dataHTML = (
      <Box>
        <div className="error-print">
          <Typography variant="p">Patient Not Found!</Typography>
          <br />
          <Typography variant="p">
            Please enter valid Token Number or you can add new fresh entry.
          </Typography>
        </div>
        <Button
          variant="outlined"
          size="large"
          className="btn-regular"
          onClick={handleError}
        >
          Add New Patient
        </Button>
      </Box>
    );
  }

  if (patientDetail !== null && resetData === false) {
    dataHTML = (
      <Box sx={{ mt: 6, boxShadow: 2, borderRadius: 1, p: 1 }}>
        <Typography variant="h4" align="center">
          Patient Details
        </Typography>
        <hr />

        <PatientForm
          patientData={patientDetail}
          setPatientData={receiveChildData}
          newPatient={false}
        ></PatientForm>
      </Box>
    );
  }

  if (isLoading) {
    dataHTML = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }

  return dataHTML;
};

export default PatientDetails;
