import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  Button,
  TablePagination,
} from "@mui/material";
import { Dna } from "react-loader-spinner";

const PatientList = () => {
  const [patients, getPatients] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    document.title = "All Visited Patient List";
  });
  const columns = [
    "#",
    "TokenNo",
    "Patient Name",
    "Gender",
    "Age",
    "City",
    "Phone Number",
    "",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchPatientHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/patient/allVisitedPatients",
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }

      const data = await response.json();
      const transformedPatient = data.map((patientList) => {
        return {
          tokenNo: patientList._id,
          name: patientList.patientName,
          gender: patientList.gender,
          age: patientList.age,
          city: patientList.city,
          phoneNumber: patientList.phoneNumber,
        };
      });
      getPatients(transformedPatient);
      setLoading(false);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchPatientHandler();
  }, [fetchPatientHandler]);

  useEffect(() => {
    const searchItems = () => {
      if (searchInput !== "") {
        const filteredData = patients.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase());
        });
        setFilteredResults(filteredData);
      } else {
        setFilteredResults(patients);
      }
    };
    searchItems();
  }, [patients, searchInput]);

  const navigate = useNavigate();
  const navigateToDetails = (event) => {
    const patientToken = event.target.id;
    navigate("/Reception/PatientDetails/" + patientToken, {
      state: {
        TokenNo: patientToken,
      },
    });
  };

  let patientList = (
    <Box align="center" sx={{ boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h4">Patients List</Typography>
      <hr />
      <Typography variant="h6">No Patients Found</Typography>
    </Box>
  );

  if (patients.length > 0) {
    patientList = (
      <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
        <Box display="flex" justifyContent="space-between" sx={{ m: 1 }}>
          <Typography variant="h4">All Patients List</Typography>
          <TextField
            size="small"
            className="search-input"
            icon="search"
            placeholder="Search"
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Box>
        <hr />
        <Box align="center">
          <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      size="small"
                      key={column}
                      className="table-font-head"
                      align="center"
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {searchInput.length > 1 ? (
                <TableBody>
                  {filteredResults
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((patients, index) => (
                      <TableRow key={index}>
                        <TableCell
                          scope="row"
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.tokenNo}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.name}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.gender}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.age}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.city}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.phoneNumber}
                        </TableCell>
                        <TableCell size="small" align="center">
                          <Button
                            id={patients.tokenNo}
                            onClick={navigateToDetails}
                            variant="outlined"
                            size="medium"
                            className="btn-regular"
                          >
                            See Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                <TableBody>
                  {patients
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((patients, index) => (
                      <TableRow key={index}>
                        <TableCell
                          scope="row"
                          size="small"
                          className="table-font-row"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.tokenNo}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.name}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.gender}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.age}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.city}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className="table-font-row"
                        >
                          {patients.phoneNumber}
                        </TableCell>
                        <TableCell size="small" align="center">
                          <Button
                            id={patients.tokenNo}
                            onClick={navigateToDetails}
                            variant="outlined"
                            size="medium"
                            className="btn-regular"
                          >
                            See Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={patients.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    );
  }
  if (isLoading) {
    patientList = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }
  return <div>{patientList}</div>;
};

export default PatientList;
