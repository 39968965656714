import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TextField,
  Box,
  FormControl,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import MedicineTable from "../PatientTreatment/MedicineTable";
import { Dna } from "react-loader-spinner";

const AddTreatment = () => {
  const navigate = useNavigate();
  const navigateToDiseaseList = () => {
    navigate("/Doctor/Treatments/");
  };
  useEffect(() => {
    document.title = "Edit Treatment";
  });
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { state } = useLocation();
  const [diseaseName, setDiseaseName] = useState(state.diseaseName);
  const diseaseId = state.diseaseId;
  const [diseaseErr, setdiseaseErr] = useState("");
  const [error, setError] = useState(false);

  const errorMsg = {
    diseaseErr: "",
    medicine: "",
    qty: "",
  };

  const addTableRows = () => {
    const rowsInput = {
      medicineType: "Tab",
      medicineName: "",
      qty: "",
      doseTapering: "None",
      morning: "",
      afternoon: "",
      evening: "",
      night: "",
      instruction: "None",
      diseaseID: diseaseId,
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const getDiseaseData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/prescriptionTemplate/prescriptionTemplateByDisease/" +
          diseaseId,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
      }
      const data = await response.json();
      const transformedPrescription = data.map((prescription) => {
        return {
          medicineType: prescription.medicineType,
          medicineName: prescription.medicineName,
          qty: prescription.qty,
          doseTapering: prescription.doseTapering,
          morning: prescription.morning,
          afternoon: prescription.afternoon,
          evening: prescription.evening,
          night: prescription.night,
          instruction: prescription.instruction,
          diseaseID: diseaseId,
        };
      });
      setRowsData(transformedPrescription);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [diseaseId]);

  useEffect(() => {
    getDiseaseData();
  }, [getDiseaseData]);

  let errorFlag = 0;

  const updateTemplate = async () => {
    let i = 0;
    while (i < rowsData.length) {
      if (rowsData[i].medicineName === "") {
        errorMsg.medicine = "Medicine Name Cannot be empty!\n";
        errorFlag = 1;
      }
      if (rowsData[i].qty === "") {
        errorMsg.qty = "Quantity cannot be empty!\n";
        errorFlag = 1;
      }
      i++;
    }
    if (diseaseName === "") {
      errorMsg.diseaseErr = "Please Enter Disease Name!\n";
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            "/prescriptionTemplate/updatePrescription/" +
            diseaseId,
          {
            method: "PUT",
            body: JSON.stringify(rowsData),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": localStorage.getItem("authToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.status);
        }
        if (response.status === 200) {
          setLoading(false);
          alert("Updated Successfully!");
          navigateToDiseaseList();
        }
      } catch (error) {
        if (error.message === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      }
    } else {
      alert(errorMsg.diseaseErr + errorMsg.medicine + errorMsg.qty);
    }
  };

  const updateTreatment = async () => {
    if (diseaseName.length > 0) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/disease/update/" + diseaseId,
          {
            method: "PUT",
            body: JSON.stringify({
              name: diseaseName,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": localStorage.getItem("authToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.status);
        }
        if (response.status === 200) {
          updateTemplate();
        }
        await response.json();
      } catch (error) {
        if (error.message === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      }
    } else {
      setError(true);
      setdiseaseErr("Enter Disease Name");
    }
  };

  let treatmentData = (
    <div>
      <h3>No Data Found</h3>
    </div>
  );

  if (isLoading) {
    treatmentData = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }

  let saveBtn = <></>;
  if (rowsData.length > 0)
    saveBtn = (
      <div style={{ marginTop: 20 }}>
        <Button
          variant="outlined"
          size="large"
          className="btn-progress"
          onClick={updateTreatment}
        >
          Save Treatment
        </Button>
      </div>
    );
  if (!isLoading) {
    treatmentData = (
      <>
        <div>
          <Button
            variant="outlined"
            size="large"
            className="btn-regular"
            onClick={navigateToDiseaseList}
          >
            Back To Treatment List
          </Button>
        </div>
        <div style={{ marginTop: 20 }}>
          <Box sx={{ mt: 2, boxShadow: 3, borderRadius: 2, p: 2 }}>
            <Box display="flex" justifyContent="space-between" sx={{ m: 1 }}>
              <FormControl sx={{ width: "30%" }}>
                <TextField
                  required
                  id="outlined-required"
                  type="text"
                  label="Disease Name"
                  value={diseaseName}
                  onChange={(e) => {
                    setDiseaseName(e.target.value);
                    setError(false);
                    setdiseaseErr("");
                  }}
                  size="medium"
                  error={error}
                  helperText={diseaseErr}
                />
              </FormControl>
            </Box>
            <hr />
            <TableContainer sx={{ mt: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      className="table-font-head"
                      size="small"
                    ></TableCell>
                    <TableCell
                      component="th"
                      className="table-font-head"
                      size="small"
                    >
                      Med Type
                    </TableCell>
                    <TableCell
                      variant="th"
                      size="small"
                      className="table-font-head"
                    >
                      Medicine Name
                    </TableCell>
                    <TableCell
                      component="th"
                      className="table-font-head"
                      size="small"
                    >
                      QTY
                    </TableCell>
                    <TableCell
                      variant="th"
                      size="small"
                      className="table-font-head"
                    >
                      Morning
                    </TableCell>
                    <TableCell
                      variant="th"
                      size="small"
                      className="table-font-head"
                    >
                      Afternoon
                    </TableCell>
                    <TableCell
                      variant="th"
                      size="small"
                      className="table-font-head"
                    >
                      Evening
                    </TableCell>
                    <TableCell
                      variant="th"
                      size="small"
                      className="table-font-head"
                    >
                      Night
                    </TableCell>
                    <TableCell
                      component="th"
                      className="table-font-head"
                      size="small"
                    >
                      Dose Tapering
                    </TableCell>
                    <TableCell
                      variant="th"
                      size="small"
                      className="table-font-head"
                    >
                      Instruction
                    </TableCell>
                    <TableCell
                      variant="th"
                      size="small"
                      className="table-font-head"
                    >
                      <Button
                        variant="outlined"
                        size="medium"
                        className="btn-regular"
                        onClick={addTableRows}
                      >
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <MedicineTable
                    rowsData={rowsData}
                    deleteTableRows={deleteTableRows}
                    handleChange={handleChange}
                    setRowsData={setRowsData}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {saveBtn}
        </div>
      </>
    );
  }
  return treatmentData;
};

export default AddTreatment;
