import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Table,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Box,
  TextField,
} from "@mui/material";
import { Dna } from "react-loader-spinner";

const TreatmentList = () => {
  const [diseaseList, getDiseaseList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  useEffect(() => {
    document.title = "Treatments";
  });
  const fetchDiseases = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/disease/diseases",
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      getDiseaseList(data);
      setLoading(false);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchDiseases();
  }, [fetchDiseases]);

  const navigate = useNavigate();
  const navigateEditDisease = (event) => {
    let stringDiseaseID = JSON.stringify(event.currentTarget.id);
    const diseaseName = event.currentTarget.name;
    const diseaseId = stringDiseaseID.replace(/"/g, "");
    navigate("/Doctor/Treatments/EditDiseaseTreatment/" + diseaseId, {
      state: {
        diseaseId: diseaseId,
        diseaseName: diseaseName,
      },
    });
  };

  const deleteDisease = async (event) => {
    const diseaseName = event.currentTarget.name;
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/prescriptionTemplate/deletePrescriptionAndDisease/" +
          diseaseName,
        {
          method: "DELETE",
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (response.ok) {
        fetchDiseases();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const searchItems = () => {
      if (searchInput !== "") {
        const filteredData = diseaseList.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase());
        });
        setFilteredResults(filteredData);
      } else {
        setFilteredResults(diseaseList);
      }
    };
    searchItems();
  }, [diseaseList, searchInput]);

  let printDiseaseList = (
    <div style={{ marginTop: "20px" }}>
      <Typography variant="h5">No Disease-Treatment Found</Typography>
    </div>
  );
  if (isLoading) {
    printDiseaseList = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }
  if (diseaseList.length > 0) {
    printDiseaseList = (
      <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2, mt: 2 }}>
        <Box display="flex" justifyContent="space-between" sx={{ m: 1 }}>
          <Typography variant="h4">All Treatment List</Typography>
          <TextField
            size="small"
            className="search-input"
            icon="search"
            placeholder="Search"
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Box>
        <hr />
        <Box align="center">
          <TableContainer sx={{ mt: 2, boxShadow: 2, borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    variant="th"
                    size="small"
                    className="table-font-head"
                  >
                    #
                  </TableCell>
                  <TableCell
                    variant="th"
                    size="small"
                    className="table-font-head"
                  >
                    Treatment Name
                  </TableCell>
                  <TableCell
                    variant="th"
                    size="small"
                    className="table-font-head"
                  >
                    Edit
                  </TableCell>
                  <TableCell
                    variant="th"
                    size="small"
                    className="table-font-head"
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              {searchInput.length > 1 ? (
                <TableBody>
                  {filteredResults.map((disease, index) => (
                    <TableRow key={index}>
                      <TableCell
                        scope="row"
                        size="small"
                        className="table-font-row"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell size="small" className="table-font-row">
                        {disease.name}
                      </TableCell>
                      <TableCell size="small" className="table-font-row">
                        <Button
                          className="btn-regular"
                          size="medium"
                          variant="outlined"
                          onClick={navigateEditDisease}
                          id={disease._id}
                          name={disease.name}
                        >
                          Edit Treatment
                        </Button>
                      </TableCell>
                      <TableCell size="small" className="table-font-row">
                        <Button
                          className="btn-danger"
                          size="medium"
                          variant="outlined"
                          onClick={deleteDisease}
                          id={disease._id}
                          name={disease.name}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  {diseaseList.map((disease, index) => (
                    <TableRow key={index}>
                      <TableCell
                        scope="row"
                        size="small"
                        className="table-font-row"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell size="small" className="table-font-row">
                        {disease.name}
                      </TableCell>
                      <TableCell size="small" className="table-font-row">
                        <Button
                          className="btn-regular"
                          size="medium"
                          variant="outlined"
                          onClick={navigateEditDisease}
                          id={disease._id}
                          name={disease.name}
                        >
                          Edit Treatment
                        </Button>
                      </TableCell>
                      <TableCell size="small" className="table-font-row">
                        <Button
                          className="btn-danger"
                          size="medium"
                          variant="outlined"
                          onClick={deleteDisease}
                          id={disease._id}
                          name={disease.name}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }

  return printDiseaseList;
};

export default TreatmentList;
