import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
const Settings = () => {
  const [prescriptionFormat, setPrescriptionFormat] = useState(
    sessionStorage.getItem("format")
  );

  const valueRadio = (val) => {
    setPrescriptionFormat(val);
  };

  const saveSettings = () => {
    sessionStorage.setItem("format", prescriptionFormat);
    alert("Settings are saved successfully!");
  };
  return (
    <Box sx={{ mt: 2, boxShadow: 2, borderRadius: 3, p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Settings
      </Typography>
      <hr />
      <Typography variant="h6">Prescription Format</Typography>
      <RadioGroup
        value={prescriptionFormat}
        name="Prescripttion-format"
        row
        onChange={(val) => valueRadio(val.target.value)}
      >
        <FormControlLabel value="0" control={<Radio />} label="Old Format" />
        <FormControlLabel value="1" control={<Radio />} label="Devri Format" />
        <FormControlLabel value="2" control={<Radio />} label="Arjuni Format" />
      </RadioGroup>
      <Button
        variant="outlined"
        size="medium"
        className="btn-regular"
        sx={{ mt: 2 }}
        onClick={saveSettings}
      >
        Save
      </Button>
    </Box>
  );
};
export default Settings;
