import React, { useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableContainer,
  TableBody,
  Typography,
  Box,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import PatientTreatment from "./PatientTreatmentDetails";
const PatientDetails = () => {
  const [patientDetails, getPatientDetails] = useState({});

  const { state } = useLocation();
  const tokenNo = state.TokenNo;

  const fetchPatientDetails = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/patient/patientByTokenNo/" + tokenNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      getPatientDetails(data);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [tokenNo]);

  useEffect(() => {
    fetchPatientDetails();
  }, [fetchPatientDetails]);

  return (
    <div>
      <Box sx={{ mt: 2, boxShadow: 2, borderRadius: 3, p: 2 }}>
        <Typography variant="h4">Patient Treatment Details</Typography>
        <hr />
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Token No</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Patient Name</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Age</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">City</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Gender</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="body1">{tokenNo}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">
                    {patientDetails.patientName}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">{patientDetails.age}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">{patientDetails.city}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">
                    {patientDetails.gender}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PatientTreatment tokenNo={tokenNo} />
    </div>
  );
};
export default PatientDetails;
