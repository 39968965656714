import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FirstVisit from "./FirstVisit";
import SecondVisit from "./SecondVisit";
import PatientDetails from "./PatientDetails";
import PatientList from "./PatientList";

const NewPatient = () => {
  const [firstVisit, setFirstVisit] = useState("");
  const [details, setDetails] = useState(false);
  const [tokenNo, setTokenNo] = useState("");
  useEffect(() => {
    document.title = "Add New Patient";
  });
  const handleSelectVisit = (e) => {
    setFirstVisit(e.target.value);
    setTokenNo("");
    setDetails(false);
  };

  const navigate = useNavigate();
  const navigateToPatientList = () => {
    navigate("/Doctor");
  };

  let visitFlag;

  if (firstVisit === "Yes") {
    visitFlag = <FirstVisit />;
  } else if (firstVisit === "No") {
    visitFlag = <SecondVisit tokenNumber={setTokenNo} details={setDetails} />;
  } else {
    visitFlag = null;
  }
  return (
    <Grid container spacing={2}>
      <Grid container item md={7} xs={7}>
        <Grid item xs={4} md={4} sx={{ height: 2 }}>
          <Button
            variant="outlined"
            size="large"
            onClick={navigateToPatientList}
            className="btn-regular"
          >
            Back to List
          </Button>
          <Box sx={{ maxWidth: 150, mt: 3 }}>
            <FormControl fullWidth>
              <InputLabel>First Visit</InputLabel>
              <Select
                value={firstVisit}
                label="First Visit"
                onChange={handleSelectVisit}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={8} md={8}>
          {visitFlag}
        </Grid>
        <Grid item xs={12} md={12}>
          {details && (
            <PatientDetails
              tokenNo={tokenNo}
              tokenState={setTokenNo}
              visitState={setFirstVisit}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={5} md={5}>
        <PatientList />
      </Grid>
    </Grid>
  );
};
export default NewPatient;
