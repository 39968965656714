import React, { useCallback, useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import sign from "./doctorsign.png";
import TiroDevanagariMarathi from "./font/TiroDevanagariMarathi-Regular.ttf";
Font.register({
  family: "TiroDevanagariMarathi",
  src: TiroDevanagariMarathi,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  tokenNo: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "7cm",
    marginLeft: "3cm",
  },
  patientName: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "7cm",
    marginLeft: "8cm",
  },
  appointmentDate: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "7.8cm",
    marginLeft: "3cm",
  },
  patientCity: {
    fontFamily: "Helvetica-BoldOblique",
    position: "absolute",
    fontSize: "11pt",
    marginTop: "7.8cm",
    marginLeft: "15cm",
  },
  patientAge: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "7.8cm",
    marginLeft: "6.5cm",
  },
  patientGender: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "8cm",
    marginLeft: "11cm",
  },
  patientWeight: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "7.8cm",
    marginLeft: "11.5cm",
  },
  examination: {
    marginTop: "9cm",
    marginLeft: "3cm",
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "11pt",
  },
  bp: {
    marginTop: "9.8cm",
    marginLeft: "3cm",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "11pt",
  },
  bloodSugar1: {
    marginTop: "9.8cm",
    marginLeft: "6cm",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "11pt",
  },
  bloodSugar2: {
    marginTop: "9.8cm",
    marginLeft: "7cm",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "11pt",
  },
  diagnosis: {
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "11pt",
    marginTop: "11cm",
    marginLeft: "3cm",
  },
  medicineTable1: {
    marginTop: "10cm",
    marginLeft: "3cm",
  },
  medicineTable2: {
    marginTop: "10cm",
    marginLeft: "3cm",
  },
  table: {
    display: "table",
    width: "16cm",
  },
  tableHead: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11pt",
  },
  tableCell: {
    // margin: "auto",
    marginLeft: 2,
    marginTop: 5,
    fontSize: "11pt",
  },
  tableCell1: {
    marginLeft: 2,
    marginTop: 5,
    fontSize: "10pt",
  },
  tableRow: {
    flexDirection: "row",
    margin: "auto",
  },
  tableColType: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColName: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColDose: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColInstruction: {
    width: "35%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColQty: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  instruction: {
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "11pt",
  },
  comment: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "500",
    marginLeft: "50",
  },
  nextVisit: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "24cm",
    marginLeft: "3cm",
  },
  nextVisit1: {
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "11pt",
    marginTop: "23.5cm",
    marginLeft: "3cm",
  },
  sign: {
    marginTop: "22.5cm",
    marginLeft: "15cm",
    height: 40,
    position: "absolute",
    width: 80,
  },
  doctorsign: {
    marginLeft: "14cm",
    fontSize: "10pt",
    position: "relative",
  },
  footer: {
    position: "absolute",
  },
  doctorDesc: {
    marginTop: "1.6cm",
    marginLeft: "5.9cm",
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "10pt",
  },
});

const PrescriptionData = (props) => {
  const [treatmentData, getTreatmentData] = useState([]);
  const [patientDetail, getPatientDetail] = useState([]);
  const [treatmentDetail, setTreatmentDetail] = useState({});
  let tokenNo = props.tokenNo;
  let visitNo = props.visitNo;
  const fetchTreatmentHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patientTreatment/PatientTreatments/" +
          tokenNo +
          "/" +
          visitNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      const transformedTreatment = data.prescription.map((Treatment) => {
        return {
          medicineType: Treatment.medicineType,
          medicineName: Treatment.medicineName,
          qty: Treatment.qty,
          morning: Treatment.morning,
          afternoon: Treatment.afternoon,
          evening: Treatment.evening,
          night: Treatment.night,
          instruction: Treatment.instruction,
          doseTapering: Treatment.doseTapering,
        };
      });
      setTreatmentDetail(data);
      getTreatmentData(transformedTreatment);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [tokenNo, visitNo]);

  useEffect(() => {
    fetchTreatmentHandler();
  }, [fetchTreatmentHandler]);

  const fetchPatientDetails = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/patientByTokenNoAndVisitNo/" +
          tokenNo +
          "/" +
          visitNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      getPatientDetail(data);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [tokenNo, visitNo]);

  useEffect(() => {
    fetchPatientDetails();
  }, [fetchPatientDetails]);

  const medicineTableRows = treatmentData.map((element, index) => {
    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableColType}>
          <Text style={styles.tableCell}>{element.medicineType}</Text>
        </View>
        <View style={styles.tableColName}>
          <Text style={styles.tableCell}>{element.medicineName}</Text>
        </View>
        <View style={styles.tableColDose}>
          <Text style={styles.tableCell}>
            <Text key={element.id}>
              {element.morning === ""
                ? ""
                : element.morning === "0"
                ? ""
                : element.morning + "--"}
            </Text>
            <Text key={element.id}>
              {element.afternoon === ""
                ? ""
                : element.afternoon === "0"
                ? ""
                : element.morning === "" && element.night === ""
                ? "--" + element.afternoon + "--"
                : element.morning === ""
                ? "--" + element.afternoon
                : element.night === ""
                ? element.afternoon + "--"
                : element.afternoon}
            </Text>
            <Text>
              {element.evening === ""
                ? ""
                : element.evening === "0"
                ? ""
                : "--" + element.evening}
            </Text>
            <Text key={element.id}>
              {element.night === ""
                ? ""
                : element.night === "0"
                ? ""
                : element.morning === "" && element.afternoon === ""
                ? "-------" + element.night
                : element.afternoon === ""
                ? "----" + element.night
                : "--" + element.night}
            </Text>
            <Text key={element.id} style={styles.instruction}>
              {element.doseTapering === "None"
                ? ""
                : "\n" + element.doseTapering}
            </Text>
          </Text>
        </View>
        <View style={styles.tableColInstruction}>
          <Text style={[styles.tableCell, styles.instruction]}>
            {element.instruction === "None" ? "" : element.instruction}
          </Text>
        </View>
        <View style={styles.tableColQty}>
          <Text style={styles.tableCell}>{element.qty}</Text>
        </View>
      </View>
    );
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.doctorDesc}>(आयुर्वेदिक त्वचारोग चिकित्सक)</Text>
          <Text style={styles.tokenNo}>
            Token No :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>{tokenNo}</Text>
          </Text>
          <Text style={styles.patientName}>
            Patient Name :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {patientDetail.patientName}
            </Text>
          </Text>
          <Text style={styles.appointmentDate}>
            Date :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {treatmentDetail.treatmentDate}
            </Text>
          </Text>
          <Text style={styles.patientCity}>
            City :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {patientDetail.city}
            </Text>
          </Text>
          <Text style={styles.patientAge}>
            Age/Sex :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {patientDetail.age +
                " " +
                patientDetail.ageUnit +
                "/" +
                patientDetail.gender}
            </Text>
          </Text>
          {/* <Text style={styles.patientGender}>
            Gender :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {patientDetail.gender}
            </Text>
          </Text> */}
          <Text style={styles.patientWeight}>
            {patientDetail.weight !== null
              ? "Weight : " + patientDetail.weight + " kg"
              : ""}
          </Text>

          {treatmentDetail.bp !== "" || treatmentDetail.bloodSugar !== "" ? (
            <Text style={styles.examination}>ON EXAMINATION</Text>
          ) : (
            ""
          )}
          {treatmentDetail.bp !== "" ? (
            <Text style={styles.bp}>BP: {treatmentDetail.bp} mmHg </Text>
          ) : (
            ""
          )}

          {treatmentDetail.bloodSugar !== "" ? (
            <Text
              style={
                treatmentDetail.bp === ""
                  ? styles.bloodSugar1
                  : styles.bloodSugar2
              }
            >
              Blood Sugar: {treatmentDetail.bloodSugar} mg/dL
            </Text>
          ) : (
            ""
          )}
          <Text style={styles.diagnosis}>
            {treatmentDetail.diagnosis !== undefined
              ? treatmentDetail.diagnosis.length !== 0
                ? "Diagnosis: " + treatmentDetail.diagnosis
                : ""
              : ""}
          </Text>
          <View
            style={[
              styles.table,
              [
                treatmentDetail.bp === "" ||
                treatmentDetail.bloodSugar === "" ||
                treatmentDetail.diagnosis === undefined ||
                treatmentDetail.diagnosis.length === 0
                  ? styles.medicineTable1
                  : styles.medicineTable2,
              ],
            ]}
          >
            <View style={[styles.tableRow, styles.tableHead]}>
              <View style={styles.tableColType}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableColName}>
                <Text style={styles.tableCell}>Medication</Text>
              </View>
              <View style={styles.tableColDose}>
                <Text style={styles.tableCell}>Dose</Text>
              </View>
              <View style={styles.tableColInstruction}>
                <Text style={styles.tableCell}>Instruction</Text>
              </View>
              <View style={styles.tableColQty}>
                <Text style={styles.tableCell}>Qty</Text>
              </View>
            </View>
            {medicineTableRows}
          </View>
          <View style={styles.footer}>
            <Text style={styles.nextVisit1}>
              {treatmentDetail.nextVisit === "Not Required"
                ? ""
                : "फेर तपासणी तारीख"}
            </Text>
            <Text style={styles.nextVisit}>
              {treatmentDetail.nextVisit === "Not Required"
                ? ""
                : "Next Visit : " + treatmentDetail.nextVisit}
            </Text>
            <Image src={sign} style={styles.sign} />
            <Text style={styles.doctorsign}>Dr. Tarun Kumar Tapan Mandal</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default PrescriptionData;
