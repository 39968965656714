import React, { useEffect } from "react";
import Header from "../../../DoctorDashboard/DoctorContent/HomeContent/Header";
import PatientList from "../../../DoctorDashboard/DoctorContent/HomeContent/PatientList";

const Home = () => {
  useEffect(() => {
    document.title = "Home | Doctor";
  });
  return (
    <div>
      <Header />
      <PatientList />
    </div>
  );
};

export default Home;
