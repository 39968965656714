import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  TextField,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

function MedicineTable({
  rowsData,
  deleteTableRows,
  handleChange,
  setRowsData,
}) {
  const [medicineNames, setMedicineNames] = useState([]);

  const fetchMedicineName = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/medicine/medicineNames",
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
      }
      const data = await response.json();
      setMedicineNames(data);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchMedicineName();
  }, [fetchMedicineName]);

  return rowsData.map((data, index) => {
    const {
      medicineType,
      medicineName,
      qty,
      doseTapering,
      morning,
      afternoon,
      evening,
      night,
      instruction,
    } = data;

    const mediType = [
      "Tab",
      "Cap",
      "Susp",
      "Syp",
      "Liq",
      "Cream",
      "Tube",
      "Pwd",
      "Gel",
      "Soln",
      "Lotn",
      "Serum",
      "Gargle",
      "Oint",
      "Lili",
      "Oil",
      "Drop",
      "Soap",
      "Shmp",
      "Inj",
      "IV",
      "Facewash",
      "Roll On",
    ];

    const TabCap = [
      "None",
      "जेवणानंतर",
      "उपाश्यापोटी",
      "एक गोळी रात्रोला लावणे",
      "जेवणानंतर चोखून खाणे",
      "उपाश्यापोटी चोखून खाणे",
      "तापअसल्यास खाणे",
      "पोट दुखल्यास खाणे",
      "उल्टी वाटल्यास खाणे",
      "दुखल्यास खाणे",
      "सकाळी नास्ता अगोदर खाणे",
      "सकाळी नास्ता केल्यानंतर खाणे",
    ];

    const SypLiqSusp = [
      "None",
      "जेवणानंतर",
      "उपाश्यापोटी",
      "रात्रोला झोपतावेळी",
      "तापअसल्यास पिणे",
      "पोट दुखल्यास पिणे",
      "उल्टी वाटल्यास पिणे",
      "समभाग पाण्यात टाकून पिणे",
      "एक ग्लास पाण्यात टाकून पिणे",
      "दुखल्यास खाणे",
      "सकाळी नास्ता अगोदर खाणे",
      "सकाळी नास्ता केल्यानंतर खाणे",
      "अर्धा कप कोमट पाण्यात टाकून पिणे",
    ];

    const CreamGelOintLiliTube = [
      "None",
      "रात्रोला लावणे",
      "दिवसा आणि रात्रोला लावणे",
      "दिवसा आणि रात्रोला अंगाला लावणे",
      "काळ्या दागांवर लावणे",
      "रात्रोला काळ्या दागांवर लावणे",
      "चेहर्‍यावर लावणे",
      "दिवसा चेहर्‍यावर लावणे",
      "रात्रोला चेहर्‍यावर लावणे",
      "रात्रोला डार्क सर्कलवर लावणे",
      "दिवसातून 3 ते 4 दा चेहर्‍यावर लावणे",
      "पांढर्‍या दागांवर सकाळ आणि सायंकाळ लावणे",
      "दुखत्याजागी लावणे",
      "मालीश करणे",
      "मलम सकाळ आणि सायंकाळला लाऊन अर्धा तास सावलीत राहणे नंतर 5 मिनिटांची कोवळी ऊन दाखवणे",
      "दिवसा आणि रात्रोला संपूर्ण अंगाला लावणे",
      "सकाळ-दुपार-सायंकाळ डोळ्यात लावणे",
      "उन्हात जातांनी लावणे",
    ];

    const SolLotionOil = [
      "None",
      "पूर्ण अंगाला लावणे",
      "पूर्ण अंगाला दिवसातून दोनदा लावणे",
      "दिवसा आणि रात्रोला अंगाला लावणे",
      "मालीश करणे",
      "डोक्याला तेलासारखा लावणे",
      "दोनवेळा लावणे",
      "दिवसा चेहर्‍यावर लावणे",
      "रात्रोला चेहर्‍यावर लावणे",
    ];

    const Gargle = [
      "None",
      "अर्धा कप कोमट पाण्यात, 2.5 ml औषधी टाकून कुर्ला करणे",
      "अर्धा कप कोमट पाण्यात, 5 ml औषधी टाकून कुर्ला करणे",
      "अर्धा कप कोमट पाण्यात, 10 ml औषधी टाकून कुर्ला करणे",
    ];

    const Soap = [
      "None",
      "अंघोड करणे",
      "दोनदा अंघोड करणे",
      "चेहरा धुणे",
      "दोनदा अंघोड करणे आणि चेहरा धुणे",
    ];
    const Powder = [
      "None",
      "सकाळ आणि सायंकाळ लावणे",
      "एक ग्लास पाण्यात टाकून पिणे",
      "पाऊण ग्लास पाण्यात एक पाकीट टाकून पिणे",
      "पाऊण ग्लास पाण्यात 3 चम्मच टाकून पिणे",
      "पाऊण ग्लास पाण्यात 1 ते 2 चम्मच टाकून पिणे",
      "एक कप कोमट पाण्यात टाकून पिणे",
    ];
    const InjectionIV = ["None"];
    const Serum = [
      "None",
      "केसांना लावणे",
      "चेहर्‍याला लावणे",
      "एक-दोन दिवसाआड लावणे",
      "रात्रोला चेहर्‍याला लावणे",
      "रात्रोला एक-दोन दिवसाआड लावणे",
    ];
    const Shampoo = ["None", "आठवड्यातून दोनदा डोकं धुणे"];

    const rollOn = ["None", "2 वेळा लावणे"];

    const facewash = ["None", "चेहरा धुणे"];

    const drop = [
      "None",
      "सकाळ-सायंकाळ घेणे",
      "सकाळ-सायंकाळ कानात टाकणे",
      "सकाळ-दुपार-सायंकाळ कानात टाकणे",
      "सकाळ-सायंकाळ डोळ्यात टाकणे",
      "सकाळ-सायंकाळ नाकात टाकणे",
      "सकाळ-दुपार-सायंकाळ डोळ्यात टाकणे",
    ];

    const doseTaperingOption = [
      "None",
      "3 दिवस",
      "5 दिवस",
      "7 दिवस",
      "10 दिवस",
      "15 दिवस",
      "3 दिवसांतर",
      "5 दिवसांतर",
      "7 दिवसांतर",
      "10 दिवसांतर",
      "15 दिवसांतर",
      "एक दिवसआळ",
      "सोम-मंगळ-बुध",
      "मंगळ-बुध-गुरु",
      "बुध-गुरु-शुक्र",
      "गुरु-शुक्र-शनि",
      "शुक्र-शनि-रवि",
      "शनि-रवि-सोम",
      "रवि-सोम-मंगळ",
      "खाज वाटल्यास खाणे",
      "एक गोळी चोखून खाणे",
      "पोट दुखल्यास खाणे",
      "उल्टी वाटल्यास खाणे",
      "डोका दुखल्यास खाणे",
      "खाजेच्या जागी लावणे",
    ];

    const OtherQty = ["1", "2", "3", "5", "10", "20", "30"];

    const dose = ["½", "1"];
    const handleDragStart = (e, index) => {
      e.dataTransfer.setData("index", index);
      e.dataTransfer.setData("data", JSON.stringify(rowsData[index]));
    };

    const handleDrop = (e, index) => {
      e.preventDefault();
      const droppedIndex = e.dataTransfer.getData("index");
      const droppedData = JSON.parse(e.dataTransfer.getData("data"));
      const newData = [...rowsData];
      newData.splice(droppedIndex, 1);
      newData.splice(index, 0, droppedData);
      setRowsData(newData);
    };

    return (
      <TableRow
        key={index}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, index)}
        onDrop={(e) => handleDrop(e, index)}
        onDragOver={(e) => e.preventDefault()}
      >
        <TableCell sx={{ width: 10, p: 1 }}>
          <FontAwesomeIcon icon={faList} />
        </TableCell>
        <TableCell sx={{ p: 1 }} scope="row" size="small">
          <Autocomplete
            options={mediType.map((mediType) => mediType)}
            style={{ width: 100 }}
            freeSolo
            autoSelect
            size="small"
            renderInput={(params) => <TextField {...params} autoFocus />}
            value={medicineType}
            onChange={(event, medicineType) => {
              if (medicineType === null) {
                medicineType = "";
              }
              handleChange(index, {
                target: { value: medicineType, name: "medicineType" },
              });
            }}
          />
        </TableCell>
        <TableCell sx={{ p: 0 }}>
          <Autocomplete
            options={medicineNames.map((medicineNames) => medicineNames)}
            style={{ width: 170 }}
            freeSolo
            autoSelect
            size="small"
            renderInput={(params) => <TextField {...params} />}
            value={medicineName}
            onChange={(event, medicineName) => {
              if (medicineName === null) {
                medicineName = "";
              }
              handleChange(index, {
                target: { value: medicineName, name: "medicineName" },
              });
            }}
          />
        </TableCell>
        <TableCell sx={{ pl: 0, pr: 1 }}>
          <Autocomplete
            options={OtherQty.map((qty) => qty)}
            style={{ width: 70 }}
            freeSolo
            size="small"
            autoSelect
            renderInput={(params) => <TextField {...params} />}
            value={qty === null ? "" : qty}
            onChange={(event, qty) => {
              if (qty === null) {
                qty = "";
              }
              handleChange(index, {
                target: { value: qty, name: "qty" },
              });
            }}
          />
        </TableCell>
        <TableCell sx={{ p: 1 }}>
          <Autocomplete
            options={dose.map((dose) => dose)}
            style={{ width: 60 }}
            freeSolo
            autoSelect
            size="small"
            renderInput={(params) => <TextField {...params} />}
            value={morning === null ? "" : morning}
            onChange={(event, morning) => {
              if (morning === null) {
                morning = "";
              }
              handleChange(index, {
                target: { value: morning, name: "morning" },
              });
            }}
          />
        </TableCell>
        <TableCell sx={{ p: 0 }}>
          <Autocomplete
            options={dose.map((dose) => dose)}
            style={{ width: 60 }}
            freeSolo
            autoSelect
            size="small"
            renderInput={(params) => <TextField {...params} />}
            value={afternoon === null ? "" : afternoon}
            onChange={(event, afternoon) => {
              if (afternoon === null) {
                afternoon = "";
              }
              handleChange(index, {
                target: { value: afternoon, name: "afternoon" },
              });
            }}
          />
        </TableCell>
        <TableCell sx={{ p: 0 }}>
          <Autocomplete
            options={dose.map((dose) => dose)}
            style={{ width: 60 }}
            freeSolo
            autoSelect
            size="small"
            renderInput={(params) => <TextField {...params} />}
            value={evening === null ? "" : evening}
            onChange={(event, evening) => {
              if (evening === null) {
                evening = "";
              }
              handleChange(index, {
                target: { value: evening, name: "evening" },
              });
            }}
          />
        </TableCell>
        <TableCell sx={{ p: 1 }}>
          <Autocomplete
            options={dose.map((dose) => dose)}
            style={{ width: 60 }}
            freeSolo
            autoSelect
            size="small"
            renderInput={(params) => <TextField {...params} />}
            value={night === null ? "" : night}
            onChange={(event, night) => {
              if (night === null) {
                night = "";
              }
              handleChange(index, {
                target: { value: night, name: "night" },
              });
            }}
          />
        </TableCell>
        <TableCell sx={{ p: 1 }}>
          <FormControl fullWidth>
            <Select
              name="doseTapering"
              value={doseTapering}
              onChange={(evnt) => handleChange(index, evnt)}
              size="small"
              sx={{ width: 120 }}
            >
              {doseTaperingOption.map((element, index) => {
                return (
                  <MenuItem key={index} value={element}>
                    {element}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell sx={{ p: 1 }}>
          <FormControl fullWidth>
            <Select
              name="instruction"
              value={instruction}
              onChange={(evnt) => handleChange(index, evnt)}
              size="small"
              sx={{ width: 120 }}
            >
              {medicineType === "Tab" || medicineType === "Cap" ? (
                TabCap.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Syp" ||
                medicineType === "Liq" ||
                medicineType === "Susp" ? (
                SypLiqSusp.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Cream" ||
                medicineType === "Gel" ||
                medicineType === "Oint" ||
                medicineType === "Lili" ||
                medicineType === "Tube" ? (
                CreamGelOintLiliTube.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Soln" ||
                medicineType === "Lotn" ||
                medicineType === "Oil" ? (
                SolLotionOil.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Gargle" ? (
                Gargle.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Soap" ? (
                Soap.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Inj" || medicineType === "IV" ? (
                InjectionIV.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Serum" ? (
                Serum.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Pwd" ? (
                Powder.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Shmp" ? (
                Shampoo.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Drop" ? (
                drop.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Roll On" ? (
                rollOn.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : medicineType === "Facewash" ? (
                facewash.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem value="None">None</MenuItem>
              )}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell sx={{ p: 1 }}>
          <Button
            className="btn-danger"
            variant="outlined"
            size="medioum"
            onClick={() => deleteTableRows(index)}
          >
            Remove
          </Button>
        </TableCell>
      </TableRow>
    );
  });
}
export default MedicineTable;
