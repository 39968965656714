import React, { useCallback, useReducer, useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  Typography,
  Box,
  TableRow,
  TableCell,
  TableHead,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useNavigate } from "react-router-dom";

import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
const initialState = {
  data: {
    tokenNo: "",
    patientName: "",
    gender: "",
    age: "",
    ageUnit: "",
    city: "",
    weight: "",
    BP: "",
    bloodSugar: "",
  },
  isLoading: true,
  error: null,
};

const formReducer = (formState, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return {
        ...formState,
        data: {
          ...formState.data,
          [action.payload.name]: action.payload.value,
        },
      };
    case "FETCH_SUCCESS":
      return {
        ...formState,
        data: action.payload,
        isLoading: false,
      };
    case "FETCH_ERROR":
      return {
        ...formState,
        error: action.payload,
        isLoading: false,
      };
    default:
      return formState;
  }
};

const PatientDetails = ({
  visitno,
  setPatientData,
  setOpenHistory,
  ...props
}) => {
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const [isEditable, setIsEditable] = useState(false);
  const tokenNo = props.tokenNo;
  useEffect(() => {
    document.title = "Patient Treatment";
  });
  const fetchPatientDetails = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/patientByTokenNo/highestVisit/" +
          tokenNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      dispatch({ type: "FETCH_SUCCESS", payload: data });
      visitno(data.visitNo);
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error });
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [tokenNo, visitno]);

  useEffect(() => {
    fetchPatientDetails();
  }, [fetchPatientDetails]);

  const {
    patientName,
    gender,
    age,
    ageUnit,
    city,
    visitNo,
    BP,
    bloodSugar,
    weight,
  } = formState.data;

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch({ type: "UPDATE_FIELD", payload: { name, value } });
  };

  useEffect(() => {
    setPatientData(formState.data);
  }, [formState.data, setPatientData]);

  // const navigate = useNavigate();
  // const navigateToDetails = () => {
  //   navigate("/Doctor/PatientDetails/" + tokenNo, {
  //     state: {
  //       TokenNo: tokenNo,
  //     },
  //   });
  // };

  return (
    <Box sx={{ p: 1, boxShadow: 2, borderRadius: 3 }}>
      <Box display="flex" justifyContent="space-between" sx={{ m: 1 }}>
        <Typography variant="h4">Add Patient Treatment</Typography>
        <FontAwesomeIcon
          icon={faPenToSquare}
          className="edit-icon"
          onClick={() => setIsEditable((current) => !current)}
        />
      </Box>
      <hr />
      <Box sx={{ m: 1, borderRadius: 3 }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Token No</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Patient Name</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Age</Typography>
                </TableCell>
                <TableCell variant="th" align="center"></TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">City</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Gender</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Weight</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">BP</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Diabetes</Typography>
                </TableCell>
                <TableCell variant="th" align="center">
                  <Typography variant="h6">Visit No</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="body1">{tokenNo}</Typography>
                </TableCell>
                <TableCell align="center">
                  {!isEditable ? (
                    <Typography variant="body1">{patientName}</Typography>
                  ) : (
                    <TextField
                      size="small"
                      required
                      label="Patient Name"
                      name="patientName"
                      value={patientName || ""}
                      onChange={(e) => handleChange(e)}
                      sx={{ width: 200 }}
                    />
                  )}
                </TableCell>
                <TableCell sx={{ pr: 0 }}>
                  {/* <Typography variant="body1">{age + " " + ageUnit}</Typography> */}
                  {!isEditable ? (
                    <Typography variant="body1">
                      {age + " " + ageUnit}
                    </Typography>
                  ) : (
                    <TextField
                      required
                      size="small"
                      label="Age"
                      name="age"
                      value={age || ""}
                      sx={{ width: 60 }}
                      onChange={(e) => handleChange(e)}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 3);
                      }}
                      min={0}
                      type="Number"
                    />
                  )}
                </TableCell>
                <TableCell sx={{ pl: 0 }}>
                  {/* <Typography variant="body1">{age + " " + ageUnit}</Typography> */}
                  {!isEditable ? (
                    <Typography variant="body1"></Typography>
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel>Age</InputLabel>
                      <Select
                        size="small"
                        name="ageUnit"
                        value={ageUnit}
                        label="Age"
                        onChange={(e) => handleChange(e)}
                        sx={{ width: 90 }}
                      >
                        <MenuItem value="Months">Months</MenuItem>
                        <MenuItem value="Years">Years</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
                <TableCell align="center">
                  {!isEditable ? (
                    <Typography variant="body1">{city}</Typography>
                  ) : (
                    <TextField
                      required
                      size="small"
                      label="City"
                      name="city"
                      value={city || ""}
                      onChange={(e) => handleChange(e)}
                      sx={{ width: 110 }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {!isEditable ? (
                    <Typography variant="body1">{gender}</Typography>
                  ) : (
                    <FormControl>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        size="small"
                        value={gender || ""}
                        name="gender"
                        label="Gender"
                        onChange={(e) => handleChange(e)}
                        sx={{ width: 100 }}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Transgender">Transgender</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
                <TableCell align="center">
                  {!isEditable ? (
                    <Typography variant="body1">{weight}</Typography>
                  ) : (
                    <TextField
                      required
                      size="small"
                      label="Weight"
                      name="weight"
                      value={weight || ""}
                      onChange={(e) => handleChange(e)}
                      sx={{ width: 60 }}
                      type="Number"
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {!isEditable ?(BP === "true" ? (
                    <Typography variant="body1" className="blink">
                      Yes
                    </Typography>
                  ) : (
                    <Typography variant="body1">No</Typography>
                  )):(
                    <FormControl>
                      <InputLabel>BP</InputLabel>
                      <Select
                        size="small"
                        value={BP || ""}
                        name="BP"
                        label="BP"
                        onChange={(e) => handleChange(e)}
                        sx={{ width: 100 }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
                <TableCell align="center">
                  {!isEditable ?(bloodSugar === "true" ? (
                    <Typography variant="body1" className="blink">
                      Yes
                    </Typography>
                  ) : (
                    <Typography variant="body1">No</Typography>
                  )):(
                    <FormControl>
                      <InputLabel>BP</InputLabel>
                      <Select
                        size="small"
                        value={BP || ""}
                        name="BP"
                        label="BP"
                        onChange={(e) => handleChange(e)}
                        sx={{ width: 100 }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">{visitNo}</Typography>
                  {visitNo > 1 ? (
                    <Button
                      // onClick={navigateToDetails}
                      onClick={() => setOpenHistory(true)}
                      variant="outlined"
                      size="small"
                      className="btn-regular"
                    >
                      See History
                    </Button>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
export default PatientDetails;
