import React, { useEffect } from "react";
import OldPrescriptionData from "./OldPrescriptionData";
import NewPrescriptionData from "./NewPrescriptionData";
import JunePrescriptionData from "./JunePrescriptionData";
import { PDFViewer } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PatientList from "./PatientList";
const Preview = () => {
  const { state } = useLocation();
  const visitNo = state.visitNo;
  const tokenNo = state.tokenNo;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Print Prescription";
  });
  const navigateToEdit = (event) => {
    let strVisitNo = JSON.stringify(event.currentTarget.id);
    const visitNo = strVisitNo.replace(/"/g, "");
    navigate("/Doctor/editPrescription/" + tokenNo + "/" + visitNo, {
      state: {
        TokenNo: tokenNo,
        visitNo: visitNo,
      },
    });
  };

  let prescriptionFormat = sessionStorage.getItem("format");

  const navigateToHome = () => {
    navigate("/Doctor");
  };

  return (
    <Grid container>
      <Grid item xs={7}>
        <PDFViewer style={{ width: 600, height: 700 }}>
          {prescriptionFormat === "0" ? (
            <OldPrescriptionData tokenNo={tokenNo} visitNo={visitNo} />
          ) : prescriptionFormat === "1" ? (
            <NewPrescriptionData tokenNo={tokenNo} visitNo={visitNo} />
          ) : (
            <JunePrescriptionData tokenNo={tokenNo} visitNo={visitNo} />
          )}
        </PDFViewer>
      </Grid>
      <Grid item xs={5}>
        <Button
          onClick={navigateToEdit}
          variant="outlined"
          className="btn-regular"
          sx={{ width: 250, height: 60, fontSize: 20 }}
          id={visitNo}
        >
          Edit Prescription
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={navigateToHome}
          variant="outlined"
          className="btn-regular"
          sx={{ width: 250, height: 60, fontSize: 20 }}
          id={visitNo}
        >
          Go To Home
        </Button>
        <br />
        <br />
        <PatientList />
      </Grid>
    </Grid>
  );
};
export default Preview;
