import React, { useCallback, useEffect, useState } from "react";
import MedicineTable from "../PatientTreatment/MedicineTable";
import { Dna } from "react-loader-spinner";
import {
  Table,
  TableContainer,
  TableBody,
  Box,
  TableRow,
  TableCell,
  TableHead,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const MedicineList = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [nextVisitDays, setNextVisitDays] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [patientTreatmentData, setPatientTreatmentData] = useState();

  let nextVisitDate;
  if (nextVisitDays !== "Not Required") {
    let nextVisitDateStr = new Date(
      Date.now() + nextVisitDays * 24 * 60 * 60 * 1000
    );
    let nextVisitDay = nextVisitDateStr.getDate();
    let nextVisitMonth = nextVisitDateStr.getMonth() + 1;
    let nextVisitYear = nextVisitDateStr.getFullYear();

    if (nextVisitDay <= 9 && nextVisitMonth <= 9) {
      nextVisitDate = `0${nextVisitDay}-0${nextVisitMonth}-${nextVisitYear}`;
    } else if (nextVisitMonth <= 9) {
      nextVisitDate = `${nextVisitDay}-0${nextVisitMonth}-${nextVisitYear}`;
    } else if (nextVisitDay <= 9) {
      nextVisitDate = `0${nextVisitDay}-${nextVisitMonth}-${nextVisitYear}`;
    } else {
      nextVisitDate = `${nextVisitDay}-${nextVisitMonth}-${nextVisitYear}`;
    }
  } else {
    nextVisitDate = "Not Required";
  }

  const addTableRows = () => {
    const rowsInput = {
      medicineType: "Tab",
      medicineName: "",
      qty: "",
      doseTapering: "None",
      morning: "",
      afternoon: "",
      evening: "",
      night: "",
      instruction: "None",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };
  const fetchPatientTreatment = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patientTreatment/PatientTreatments/" +
          props.tokenNo +
          "/" +
          props.visitNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      const transformedTreatment = data.prescription.map((Treatment) => {
        return {
          medicineType: Treatment.medicineType,
          medicineName: Treatment.medicineName,
          qty: Treatment.qty,
          morning: Treatment.morning,
          afternoon: Treatment.afternoon,
          evening: Treatment.evening,
          night: Treatment.night,
          instruction: Treatment.instruction,
          doseTapering: Treatment.doseTapering,
        };
      });
      setRowsData(transformedTreatment);
      setPatientTreatmentData(data);
      setNextVisitDays(data.nextVisitDays);
      setComment(data.comment);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [props.tokenNo, props.visitNo]);

  useEffect(() => {
    fetchPatientTreatment();
  }, [fetchPatientTreatment]);
  let errorFlag = false;
  const errorMsg = {
    medicine: "",
    qty: "",
  };
  const handleSave = async (event) => {
    const treatmentID = event.target.id;
    let i = 0;
    while (i < rowsData.length) {
      if (rowsData[i].medicineName === "") {
        errorMsg.medicine = "Medicine Name Cannot be empty!\n";
        errorFlag = true;
      }
      if (rowsData[i].qty === "") {
        errorMsg.qty = "Quantity cannot be empty!\n";
        errorFlag = true;
      }
      i++;
    }
    if (!errorFlag) {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            "/patientTreatment/PatientTreatment/update/" +
            treatmentID,
          {
            method: "PUT",
            body: JSON.stringify({
              prescription: rowsData,
              nextVisitDays: nextVisitDays,
              nextVisit: nextVisitDate,
              comment: comment,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": localStorage.getItem("authToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.status);
        }
        if (response.status === 200) {
          setLoading(false);
          handlePatientDataSave();
          navigateToPrint();
        }
      } catch (error) {
        if (error.message === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      }
    } else {
      alert(errorMsg.medicine + errorMsg.qty);
    }
  };

  const handlePatientDataSave = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/update/" +
          props.tokenNo +
          "/" +
          props.visitNo,
        {
          method: "PUT",
          body: JSON.stringify(props.patientData),
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
        console.log("updated successfully");
      }
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  };

  const navigate = useNavigate();
  const navigateToPrint = () => {
    navigate("/Doctor/PrintPrescription/?tokenNo=" + props.tokenNo, {
      state: {
        tokenNo: props.tokenNo,
        visitNo: props.visitNo,
      },
    });
  };
  let treatmentTag;

  if (isLoading) {
    treatmentTag = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }

  if (!isLoading) {
    treatmentTag = (
      <Box>
        <Box sx={{ mt: 2, boxShadow: 2, borderRadius: 3 }}>
          <TableContainer component={Paper} sx={{ p: 1 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  ></TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Med Type
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Medicine Name
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    QTY
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Morning
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    After
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Eve
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Night
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Dose Tapering
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Instruction
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    <Button
                      className="btn-regular"
                      variant="outlined"
                      size="large"
                      onClick={addTableRows}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <MedicineTable
                  rowsData={rowsData}
                  deleteTableRows={deleteTableRows}
                  handleChange={handleChange}
                  setRowsData={setRowsData}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
          <Box>
            <FormControl>
              <InputLabel>Next Visit</InputLabel>
              <Select
                value={nextVisitDays}
                label="Next Visit"
                onChange={(e) => setNextVisitDays(e.target.value)}
                sx={{ width: 150 }}
              >
                <MenuItem value="Not Required">Not Required</MenuItem>
                <MenuItem value="3">3 Days</MenuItem>
                <MenuItem value="5">5 Days</MenuItem>
                <MenuItem value="7">7 Days</MenuItem>
                <MenuItem value="8">8 Days</MenuItem>
                <MenuItem value="10">10 Days</MenuItem>
                <MenuItem value="15">15 Days</MenuItem>
                <MenuItem value="20">20 Days</MenuItem>
                <MenuItem value="30">30 Days</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            multiline
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            name="Comment"
            label="Add Comment"
            sx={{ mr: 3, width: 350 }}
          />
          <Box></Box>
          <Box>
            <Button
              className="btn-progress"
              variant="outlined"
              size="large"
              onClick={handleSave}
              id={
                patientTreatmentData !== undefined
                  ? patientTreatmentData._id
                  : ""
              }
            >
              Save Treatment
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  return <div>{treatmentTag}</div>;
};

export default MedicineList;
