import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  Box,
  TableRow,
  TableCell,
  TableHead,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Button,
  Paper,
} from "@mui/material";

import MedicineTable from "./MedicineTable";
import { useNavigate } from "react-router-dom";
import { Dna } from "react-loader-spinner";

const MedicineList = (props) => {
  useEffect(() => {
    document.title = "Patient Treatment";
  });
  const [nextVisit, setNextVisit] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [comment, SetComment] = useState("");
  const [diagnosis, setDiagnosis] = useState("");

  let diseaseName = props.diseaseData.name;

  let checkNewDisease = false;
  let diseaseID = null;

  if (diseaseName.substring(0, 3) === "Add") {
    diseaseName = diseaseName.replace(/"/g, "");
    diseaseName = diseaseName.replace(/Add /g, "");
    // diseaseName = diseaseName.slice(5, diseaseName.length - 1);
    checkNewDisease = true;
    diseaseID = "97b3b1db63839c8a8ce1adcf";
  } else {
    diseaseID = props.diseaseData.id;
  }

  const tokenNo = props.tokenNo;
  const visitNo = props.visitNo;
  const errorMsg = {
    nextVisit: "",
    medicine: "",
    qty: "",
  };
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let todayDate;
  if (day <= 9 && month <= 9) {
    todayDate = `0${day}-0${month}-${year}`;
  } else if (month <= 9) {
    todayDate = `${day}-0${month}-${year}`;
  } else if (day <= 9) {
    todayDate = `0${day}-${month}-${year}`;
  } else {
    todayDate = `${day}-${month}-${year}`;
  }
  let nextVisitDate;
  if (nextVisit !== "Not Required") {
    let nextVisitDateStr = new Date(
      Date.now() + nextVisit * 24 * 60 * 60 * 1000
    );
    let nextVisitDay = nextVisitDateStr.getDate();
    let nextVisitMonth = nextVisitDateStr.getMonth() + 1;
    let nextVisitYear = nextVisitDateStr.getFullYear();

    if (nextVisitDay <= 9 && nextVisitMonth <= 9) {
      nextVisitDate = `0${nextVisitDay}-0${nextVisitMonth}-${nextVisitYear}`;
    } else if (nextVisitMonth <= 9) {
      nextVisitDate = `${nextVisitDay}-0${nextVisitMonth}-${nextVisitYear}`;
    } else if (nextVisitDay <= 9) {
      nextVisitDate = `0${nextVisitDay}-${nextVisitMonth}-${nextVisitYear}`;
    } else {
      nextVisitDate = `${nextVisitDay}-${nextVisitMonth}-${nextVisitYear}`;
    }
  } else {
    nextVisitDate = "Not Required";
  }

  const getTreatmentData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/prescriptionTemplate/prescriptionTemplateByDisease/" +
          diseaseID,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
      }
      const data = await response.json();
      const transformedPrescription = data.map((prescription) => {
        return {
          medicineType: prescription.medicineType,
          medicineName: prescription.medicineName,
          qty: prescription.qty,
          doseTapering: prescription.doseTapering,
          morning: prescription.morning,
          afternoon: prescription.afternoon,
          evening: prescription.evening,
          night: prescription.night,
          instruction: prescription.instruction,
        };
      });
      setRowsData(transformedPrescription);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [diseaseID]);

  useEffect(() => {
    getTreatmentData();
  }, [getTreatmentData]);

  const addTableRows = () => {
    const rowsInput = {
      medicineType: "Tab",
      medicineName: "",
      qty: "",
      doseTapering: "None",
      morning: "",
      afternoon: "",
      evening: "",
      night: "",
      instruction: "None",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  let errorFlag = false;

  function isIncreasing(rowsData) {
    for (let i = 0; i < rowsData.length - 1; i++) {
      if (rowsData[i] > rowsData[i + 1]) {
        return false;
      }
    }
    return true;
  }

  const updateTemplate = async () => {
    const AllRowsData = rowsData.map((obj) => {
      return { ...obj, diseaseID: diseaseID };
    });
    setLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/prescriptionTemplate/updatePrescription/" +
          diseaseID,
        {
          method: "PUT",
          body: JSON.stringify(AllRowsData),
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
        console.log("updated successfully");
      }
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  };

  const saveTreatment = async () => {
    let i = 0;
    while (i < rowsData.length) {
      if (rowsData[i].medicineName === "") {
        errorMsg.medicine = "Medicine Name Cannot be empty!\n";
        errorFlag = true;
      }
      if (rowsData[i].qty === "") {
        errorMsg.qty = "Quantity cannot be empty!\n";
        errorFlag = true;
      }
      i++;
    }
    if (nextVisit === "") {
      errorFlag = true;
      errorMsg.nextVisit = "Please Select Next Visit!\n";
    }
    if (!errorFlag) {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            "/patientTreatment/addPatientTreatment",
          {
            method: "POST",
            body: JSON.stringify({
              diseaseName: diseaseName,
              tokenNo: tokenNo,
              treatmentDate: todayDate,
              nextVisit: nextVisitDate,
              nextVisitDays: nextVisit,
              visitNo: props.visitNo,
              prescription: rowsData,
              comment: comment,
              bp: props.bp,
              bloodSugar: props.bloodSugar,
              diagnosis: diagnosis,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": localStorage.getItem("authToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.status);
        }
        if (response.status === 200) {
          setLoading(false);
          if (checkNewDisease) {
            saveNewTreatment();
            // alert("Treatment and New Disease saved successfully");
          }
          //  else {
          //   alert("Treatment saved successfully");
          // }
          updateVisit();
          if (isIncreasing(rowsData) && !checkNewDisease) {
            updateTemplate();
          }
          handlePatientDataSave();
          navigateToPrint();
        }
      } catch (error) {
        if (error.message === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      }
    } else {
      alert(errorMsg.nextVisit + errorMsg.medicine + errorMsg.qty);
    }
  };

  const updateVisit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/visitUpdate/" +
          tokenNo +
          "/" +
          props.visitNo,
        {
          method: "PUT",
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
        console.log("updated successfully");
      }
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  };

  const saveNewDisease = async () => {
    if (diseaseName.length > 0) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/disease/addDisease",
          {
            method: "Post",
            body: JSON.stringify({
              name: diseaseName,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": localStorage.getItem("authToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.status);
        }
        if (response.status === 200) {
          const data = await response.json();
          return data._id;
        }
      } catch (error) {
        if (error.message === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      }
    }
  };

  const saveNewTreatment = async () => {
    let i = 0;
    while (i < rowsData.length) {
      if (rowsData[i].medicineName === "") {
        errorMsg.medicine = "Medicine Name Cannot be empty!\n";
        errorFlag = true;
      }
      if (rowsData[i].qty === "") {
        errorMsg.qty = "Quantity cannot be empty!\n";
        errorFlag = true;
      }
      i++;
    }
    if (!errorFlag) {
      const diseaseID = await saveNewDisease();
      const AllRowsData = rowsData.map((obj) => {
        return { ...obj, diseaseID: diseaseID };
      });
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            "/prescriptionTemplate/addPrescription",
          {
            method: "Post",
            body: JSON.stringify(AllRowsData),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": localStorage.getItem("authToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.status);
        }
        if (response.status === 200) {
          setLoading(false);
          setRowsData([]);
          console.log("New Disease Added");
        }
        await response.json();
      } catch (error) {
        if (error.message === "401") {
          alert("You have been logged out !");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      }
    } else {
      alert(errorMsg.medicine + errorMsg.qty);
    }
  };

  const handlePatientDataSave = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/update/" +
          tokenNo +
          "/" +
          visitNo,
        {
          method: "PUT",
          body: JSON.stringify(props.patientData),
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      if (response.status === 200) {
        setLoading(false);
        console.log("updated successfully");
      }
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  };

  const navigate = useNavigate();
  const navigateToPrint = () => {
    navigate("/Doctor/PrintPrescription/?tokenNo=" + tokenNo, {
      state: {
        tokenNo: tokenNo,
        visitNo: props.visitNo,
      },
    });
  };

  let treatmentTag;
  if (isLoading) {
    treatmentTag = (
      <div className="overlay">
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "absolute", top: "40%", left: "45%" }}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }

  if (!isLoading) {
    treatmentTag = (
      <Box>
        <Box sx={{ mt: 2, boxShadow: 2, borderRadius: 3 }}>
          <TableContainer component={Paper} sx={{ p: 1 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  ></TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Med Type
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Medicine Name
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    QTY
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Morn
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    After
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Eve
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Night
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Dose Tapering
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    Instruction
                  </TableCell>
                  <TableCell
                    component="th"
                    className="table-font-head"
                    size="small"
                  >
                    <Button
                      className="btn-regular"
                      variant="outlined"
                      size="large"
                      onClick={addTableRows}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <MedicineTable
                  rowsData={rowsData}
                  deleteTableRows={deleteTableRows}
                  handleChange={handleChange}
                  setRowsData={setRowsData}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
          <Box>
            <FormControl>
              <InputLabel>Next Visit</InputLabel>
              <Select
                value={nextVisit}
                label="Next Visit"
                onChange={(e) => setNextVisit(e.target.value)}
                sx={{ width: 150 }}
              >
                <MenuItem value="Not Required">Not Required</MenuItem>
                <MenuItem value="3">3 Days</MenuItem>
                <MenuItem value="5">5 Days</MenuItem>
                <MenuItem value="7">7 Days</MenuItem>
                <MenuItem value="8">8 Days</MenuItem>
                <MenuItem value="10">10 Days</MenuItem>
                <MenuItem value="15">15 Days</MenuItem>
                <MenuItem value="20">20 Days</MenuItem>
                <MenuItem value="30">30 Days</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <TextField
                value={diagnosis}
                onChange={(e) => setDiagnosis(e.target.value)}
                name="Diagnosis"
                label="Diagnosis"
                sx={{ ml: 2, width: 350 }}
              />
            </FormControl>
          </Box>
          <Box>
            <TextField
              multiline
              rows={3}
              value={comment}
              onChange={(e) => SetComment(e.target.value)}
              name="Comment"
              label="Add Comment"
              sx={{ mr: 3, width: 350 }}
            />
            <Button
              className="btn-progress"
              variant="outlined"
              size="large"
              onClick={saveTreatment}
            >
              Save Treatment
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  return <div>{treatmentTag}</div>;
};
export default MedicineList;
